import { useCallback, useState } from 'react';
import classNames from 'classnames';
import Button from 'Components/Button';
import { ReactComponent as CrossSvg } from './icons/cross.svg';
import { ReactComponent as CheckboxSvg } from './icons/checkbox.svg';
import { EditNativeMaskModal, closeModal } from 'entities/modal';
import './EditNativeMask.scss';
import { useSegments } from 'entities/sketch/Segment';
import { setPrevSketchState } from 'entities/tutorial';


export default function EditNativeMask({ res, rej }: EditNativeMaskModal['props']) {
    const [checked, setChecked] = useState(false);
    const segments = useSegments('self')

    const resolve = useCallback(() => {
        localStorage.setItem('edit_native', JSON.stringify(checked));
        setPrevSketchState({ showUndefined: segments.undefinedMode, hideMasks: segments.hideSegments })
        if (segments.hideSegments) segments.hideSegments = false
        if (segments.undefinedMode) segments.undefinedMode = false
        
        closeModal();
        res(null);
    }, [checked, res, segments]);

    const reject = useCallback(() => {
        closeModal();
        rej();
    }, [rej]);

    return (
        <div className="edit-native-mask-modal__container">
            <div className="edit-native-mask-modal">
                <div className="edit-native-mask-modal__exit-button-container">
                    <CrossSvg className="edit-native-mask-modal__exit-button" onClick={reject} />
                </div>
                <div className="edit-native-mask-modal__title">Are you sure?</div>
                <div className="edit-native-mask-modal__text">Unless the object is marked as transparent, you cannot indicate anything within the outlines of its mask. </div>
                <div className="edit-native-mask-modal__text">Therefore, if you need to indicate something behind a window glass, mark the glass as transparent in the table.</div>
                <div className="edit-native-mask-modal__checkbox">
                    <CheckboxSvg className={classNames({ 'pointer': true, 'edit-native-mask-modal__checkbox_checked': checked })} onClick={() => setChecked(prev => !prev)} />
                    <div className="edit-native-mask-modal__checkbox-label">Do not show this message again</div>
                </div>
                <div className="edit-native-mask-modal__buttons">
                    <Button onClick={resolve}>OK</Button>
                </div>
            </div>
        </div>
    );
}