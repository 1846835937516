import { useStore } from 'effector-react';
import { $modal, ModalType } from 'entities/modal';
import { $route, RoutePath } from 'entities/router';
import { $userId } from 'entities/user';
import { $leftSidePanel, LeftSidePanel } from 'entities/leftSidePanel';
import Sketch from './Sketch';
import Upload from './Upload';
import CreateGroup from './modals/CreateGroup';
import SketchLeaving from './modals/SketchLeaving';
import EditNativeMask from './modals/EditNativeMask';
import Error from './modals/Error';
import Ending from './Ending';
import Join from './modals/Join';
import Auth from './Auth';
import Home from './Home';
import SubmitForRendering from './SubmitForRendering';
import Account from './Account';
import Notification from 'Components/Notification';
import { $hint } from 'entities/hint';
import Hint from 'Components/Hint/Hint';
import Share from 'Components/Share';
import SaveBeforeClosing from './leftSidePanel/SaveBeforeClosing';
import Help from 'Components/Help';

export default function Router() {
    const userId = useStore($userId);
    const route = useStore($route);
    const modal = useStore($modal);
    const leftSidePanel = useStore($leftSidePanel);
    const hint = useStore($hint)


    if (!userId) return null;

    return (
        <>
            {leftSidePanel === LeftSidePanel.AUTH && <Auth />}
            <Account />
            {/* {leftSidePanel === LeftSidePanel.ACCOUNT && <Account/>} */}
            {leftSidePanel === LeftSidePanel.SUBMIT_FOR_RENDERING && <SubmitForRendering />}
            {leftSidePanel === LeftSidePanel.SAVE_BEFORE_CLOSING && <SaveBeforeClosing />}

            {(route === RoutePath.HOME || route===RoutePath.LOAD) && <Home />}
            {route === RoutePath.UPLOAD && <Upload />}
            {route === RoutePath.SKETCH && <Sketch />}
            {route === RoutePath.ENDING && <Ending />}

            {modal.type === ModalType.CREATE_GROUP && <CreateGroup {...modal.props} />}
            {modal.type === ModalType.JOIN && <Join {...modal.props} />}
            {modal.type === ModalType.EDIT_NATIVE_MASK && <EditNativeMask {...modal.props} />}
            {modal.type === ModalType.SKETCH_LEAVING && <SketchLeaving />}
            {modal.type === ModalType.ERROR && <Error />}
            {modal.type === ModalType.ATTACH_ERROR && <Error type="attachment" />}
            {modal.type === ModalType.SHARE && <Share {...modal.props} />}
        
            <Notification />
            {hint && <Hint hint={hint} />}
            <Help/>
        </>
    );
}
