import React, { useState, useRef, useEffect, useCallback } from "react";
import './Help.scss'
import { WhatIsSection, ChoosingImageSection, MaskSelectionSection, RenderingSection, InterfaceSection, CreateMaskSection, DescribeObjectsSection, GroupSection, AccountSection } from "./Sections/Sections";
import { HelpSections, readMoreItems } from "./helpers/helpers";
import { useStore } from "effector-react";
import { $route, goTo, RoutePath } from "entities/router";
import { $sketches } from "entities/sketches";
import { setFile, loadSketchFx, $segments } from "entities/sketch";
import { $tutorialDisabled, setTutorial, TutorialDisabledReasons } from "entities/tutorial";
import config from "config";
import { useSegments } from "entities/sketch/Segment";
import { $helpSection, setHelpSection } from "entities/help";
import { setHint } from "entities/hint";
import useScrollDetection from "services/useScrollDetection";

export default function Help() {

    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [isExtendedHelp, setIsExtendedHelp] = useState<boolean>(false)
    const extendedHelpRef = useRef<HTMLDivElement>(null)
    const [hasScroll, setHasScroll] = useState(false);
    const [showAgain, setShowAgain] = useState<boolean>(true)
    const helpSection = useStore($helpSection);
    const route = useStore($route)
    const sketches = useStore($sketches);
    const [loading, setLoading] = useState(false);
    const tutorialDisabled = useStore($tutorialDisabled)
    const isScrolling = useScrollDetection({ref: extendedHelpRef})

    const handleScrollDown = () => {
        const currentListRef = extendedHelpRef.current;
        if (currentListRef) {
            currentListRef.scrollTo({
                top: currentListRef.scrollTop + 200,
                behavior: 'smooth'
            });
        }
    };

    const closeHelp = () => {
        setLoading(false)
        setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })
        setIsExtendedHelp(false)
    }

    const openHelp = () => {
        setLoading(false)
        setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })
        // setCurrentHelpSection(HelpSections.WHAT_IS)
        setIsExtendedHelp(true)
    }

    useEffect(() => {
        if (helpSection.itemId) setIsExtendedHelp(true)
    }, [helpSection.itemId])


    useEffect(() => {
        if (route !== RoutePath.HOME) return setIsExtendedHelp(false)
        if (!firstLoad) return
        const showHelp = localStorage.getItem('show_help');
        if (showHelp) {
            const showHelpValue = JSON.parse(showHelp);
            setShowAgain(showHelpValue)
            showHelpValue && openHelp()
        } else closeHelp()
        setFirstLoad(false)
    }, [firstLoad, route])

    const showAgainSwitch = () => {
        setShowAgain((prev) => !prev)
        const showHelp = localStorage.getItem('show_help');
        if (showHelp) {
            const showHelpValue = JSON.parse(showHelp);
            localStorage.setItem('show_help', JSON.stringify(!showHelpValue))
        }
    }

    useEffect(() => {
        localStorage.setItem('dont_show_help', JSON.stringify(showAgain))
    }, [showAgain])

    useEffect(()=>closeHelp(), [route])

    useEffect(()=> {if(isScrolling) setHint(null)}, [isScrolling])

    const handleScroll = () => {
        const currentListRef = extendedHelpRef.current;
        if (currentListRef) {
            const scrollTop = currentListRef.scrollTop;
            const scrollHeight = currentListRef.scrollHeight;
            const clientHeight = currentListRef.clientHeight;
            const isFullyScrolled = scrollTop + clientHeight >= scrollHeight;
            setHasScroll(!isFullyScrolled);
        }
    };

    useEffect(() => {
        const currentListRef = extendedHelpRef.current;
        if (currentListRef) {
            const hasScroll = currentListRef.scrollHeight > currentListRef.clientHeight;
            setHasScroll(hasScroll);
            if (hasScroll) {
                currentListRef.addEventListener('scroll', handleScroll);
            }
            return () => {
                currentListRef.removeEventListener('scroll', handleScroll);
            };
        }
    }, [helpSection]);

    const launchTutorial = useCallback(() => {
        if (route === RoutePath.HOME) {
            closeHelp()
            const id = sketches[0].id;
            setLoading(true);
            fetch(`${config.serverUrl}/drawing/${id}.png`)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], id);
                    setFile(file);
                    loadSketchFx(id);
                    setTutorial();
                });
        } else {
            if (tutorialDisabled === TutorialDisabledReasons.NONE) {
                setTutorial();
                closeHelp();
            }
        }

    }, [route, sketches, tutorialDisabled]);

    if (loading && route !== RoutePath.SKETCH) return <Loading />;

    return (
        <div>
            <div className="help_icon" onClick={openHelp} id="help"
                onMouseEnter={() => setHint({id: 'help'})}
                onMouseLeave={() => setHint(null)}>
                <div className="help_icon__question_mark" />
            </div>
            <div className={`extended_help-wrapper ${isExtendedHelp ? 'open' : ''}`} key={helpSection.section}>
                <div className="extended_help" ref={extendedHelpRef}>
                    <div className="extended_help-box">
                        {helpSection.section === HelpSections.WHAT_IS && <WhatIsSection launchTutorial={launchTutorial} />}
                        {helpSection.section === HelpSections.CHOOSING_IMAGE && <ChoosingImageSection />}
                        {helpSection.section === HelpSections.MASK_SELECTION && <MaskSelectionSection />}
                        {helpSection.section === HelpSections.RENDERING && <RenderingSection />}
                        {helpSection.section === HelpSections.INTERFACE && <InterfaceSection />}
                        {helpSection.section === HelpSections.CREATE_MASKS && <CreateMaskSection />}
                        {helpSection.section === HelpSections.DESCRIBE && <DescribeObjectsSection />}
                        {helpSection.section === HelpSections.GROUP && <GroupSection />}
                        {helpSection.section === HelpSections.ACCOUNT && <AccountSection />}
                        <ReadMore />
                    </div>
                    <div className="extended_help-bttns_wrapper">
                        <div className="dont_show">
                            <div className="dont_show_checkbox" onClick={() => showAgainSwitch()}>
                                {!showAgain && <div className="dont_show_checkbox-arrow" />}
                            </div>
                            <p>Don't show at launch</p>
                        </div>
                        <div className="extended_help-bttns">
                            <div className={`extended_help-bttns_scroll ${hasScroll ? 'active' : ''}`}
                                id="help-scroll_down"
                                onClick={handleScrollDown}
                                onMouseEnter={() => setHint({id: 'help-scroll_down'})}
                                onMouseLeave={() => setHint(null)}>
                                <div className="scroll_arrow_down-icon" />
                            </div>
                            <div className="extended_help-bttns_close"
                                onClick={() => closeHelp()}
                                id="collapse"
                                onMouseEnter={() => setHint({id: 'collapse'})}
                                onMouseLeave={() => setHint(null)}>
                                <div className="double_arrow_right-icon" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

function ReadMore() {
    const helpSection = useStore($helpSection)
    return (
        <div className="extended_help-read_more">
            <p className="section_title">Read more</p>
            <div className="read_more_wrapper">
                {readMoreItems.map((item) => (
                    helpSection.section !== item.type &&
                    <div className="read_more-item" key={item.type} onClick={() => setHelpSection({ section: item.type, itemId: null })}>
                        <div className={`read_more-item-icon ${item.type.toLocaleLowerCase()}_icon`} />
                        <p>{item.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}


function Loading() {
    const segments = useStore($segments);

    return (
        <div className='help-loading-wrapper'>
            {segments && <UseLoading />}
            <h1>LOADING</h1>
        </div>
    );
}

function UseLoading() {
    const loaded = useSegments('loaded');

    useEffect(() => {
        if (loaded) goTo(RoutePath.SKETCH);
    }, [loaded]);

    return null;
}


