import { useCallback, useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Attachment, Segment, useEditor, useSegment, useSegments } from 'entities/sketch/Segment';
import { setElementForAttach } from 'entities/library';
import AttachmentComponent from '../Attachment';
import { ReactComponent as Checkbox } from './icons/checkbox.svg';
import { ReactComponent as AddAttachSvg } from './icons/add_attach.svg';
import { ReactComponent as AttachSvg } from './icons/attach.svg';
import { ReactComponent as DeleteSvg } from './icons/delete.svg';
import { ReactComponent as DropAttachSvg } from './icons/drop-attach.svg';
import { useHotKey } from 'hooks';
import useClickOutside from 'services/useClickOutside';
import useChildElementsHeight from 'services/useChildElementsHeight';
import { useStore } from 'effector-react';
import { $tutorial } from 'entities/tutorial';
import { Notifications, setNotification } from 'entities/notification';
import './SegmentElement.scss'
import { setHint } from 'entities/hint';


type SegmentElementProps = {
    segment: Segment;
    index: number;
    realIndex: number;
};

export default function SegmentElement({ segment, index, realIndex }: SegmentElementProps) {
    const tutorial = useStore($tutorial);
    const editingGroup = useSegments('editingGroup');
    const editing = useEditor('editing');
    const selectedElemets = useSegments('selectedElements');
    const segments = useSegments('self');
    const attachments = useSegment(segment, 'attachments');
    const description = useSegment(segment, 'description');
    const [attachmentIsOpen, setAttachmentIsOpen] = useState(false);
    const [selectedAttachments, setSelectedAttachments] = useState<Array<Attachment>>([]);

    const selected = useMemo(() => selectedElemets.includes(segment), [selectedElemets, segment]);

    const handleSelect = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (editingGroup) {
            const group = segments.editingGroup;
            if (!group) throw new Error('Group is not being edited.');
            if (!e.ctrlKey && !e.metaKey) return;
            if (group.segments.includes(segment)) throw new Error('Group has this segment.');
            else segments.addSegmentToGroup(segment, group, group.segments.length);
            return;
        }
        if (editing) return;
        let target: unknown = e.target;

        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (['checkbox', 'button'].includes(target.getAttribute('data-type') as any)) return;
            target = target.parentElement;
        }

        segments.selectOneElement(segment);
    }, [segment, segments, editingGroup, editing]);

    const handleCheckbox = useCallback(() => {
        if (editingGroup || editing) return;
        if (selected) segments.unselectElement(segment);
        else segments.selectElement(segment);
    }, [selected, segments, segment, editing, editingGroup]);

    // const handleClickAttachmentsHeaderCheckbox = useCallback(() => {
    //     if (attachments.length === selectedAttachments.length) setSelectedAttachments([]);
    //     else setSelectedAttachments(attachments);
    // }, [attachments, selectedAttachments]);

    useEffect(() => {
        if (tutorial.step === 0) return segments.unselectAllElements();
        if ((realIndex === 0 && tutorial.step === 1) ||
            (realIndex === 1 && tutorial.step === 2) ||
            (realIndex === 2 && tutorial.step === 3)) {
            segments.unselectAllElements();
            segments.selectElement(segment);
        } else if (tutorial.step === 4 || tutorial.step === 0) {
            segments.unselectAllElements();
        }
    }, [realIndex, segment, segments, tutorial.step]);


    useEffect(() => {
        if (selectedElemets.length) setSelectedAttachments([]);
    }, [selectedElemets]);

    useEffect(() => {
        if (selectedAttachments.length) segments.unselectAllElements();
    }, [selectedAttachments, segments]);

    const handleRemoveAttachments = useCallback(() => {
        selectedAttachments.forEach(attach => segment.unattach(attach));
    }, [selectedAttachments, segment]);

    useHotKey('Delete', handleRemoveAttachments);

    useEffect(() => {
        if (attachments.length > 0) setAttachmentIsOpen(true);
        else setAttachmentIsOpen(false);

    }, [attachments]);

    const segmentAttachRef = useClickOutside(() => {
        if (editingGroup || editing) setSelectedAttachments([]);
    });

    const attachmentsHeight = useChildElementsHeight({ ref: segmentAttachRef, target: attachments })

    return (
        <Draggable draggableId={segment.id} index={index}>
            {(provided, snapshot) => (
                <div {...provided.draggableProps} ref={provided.innerRef} >

                    <div id={'element_' + segment.id} className={classNames({ segment: true, 'segment_is-being-dragged': snapshot.isDragging, segment_active: selected })} onClick={handleSelect}>
                        <div className={classNames({ segment__cell1: true, })}>
                            <div className="segment__drag" {...provided.dragHandleProps} />
                            <div className="segment__checkbox">
                                <Checkbox className={classNames({ 'segment__checkbox-icon_active': selected, 'segment__checkbox-icon_inactive': (editingGroup || editing) })} onClick={handleCheckbox} data-type="checkbox" />
                            </div>
                        </div>

                        <div className="segment__cell2">
                            <div
                                id={`color_circle_${index}`}
                                onMouseEnter={()=>setHint({id: 'color_circle_', index: index.toLocaleString()})}
                                onMouseLeave={()=>setHint(null)}
                                className={classNames({ "segment__color": true, "segment__color_active": selected })}
                                style={{ backgroundColor: `rgb(${segment.color.r}, ${segment.color.g}, ${segment.color.b})` }}
                                onClick={() => segments.changeToRandomColor(segment)}
                            />
                            <div className="segment__index">{segment.name.toString().padStart(3, '0')}</div>
                        </div>
                        <div className="segment__cell4">
                            <TextareaAutosize value={description} onChange={e => segment.description = e.currentTarget.value} className="segment__desc" placeholder="Note" />
                        </div>

                        <div className="segment__cell3">
                            <div className={`segment__cell3-attachments ${attachments.length ? '' : 'segment__cell3-attachments_disabled'}`}
                                onClick={() => {
                                    if (attachments.length)
                                        setAttachmentIsOpen(prev => !prev)
                                }}>
                                {Boolean(attachments.length) ?
                                    <div className={classNames({ "segment__attach-drop": true, "segment__attach-drop_active": attachmentIsOpen })} ><DropAttachSvg /></div> :
                                    <div className='segment__attach-drop' />}
                                <div className="segment__attach-button">
                                    <AttachSvg className='segment__attach-icon' />
                                </div>
                                Attachments ({attachments.length})
                            </div>
                        </div>
                        <div className='segment__cell6'>
                            <button className='segment__attach-add_button' onClick={() => { setNotification(Notifications.MORE_ATTACHMENTS_INFO); setElementForAttach(segment) }} >
                                <AddAttachSvg />
                                Add
                            </button>
                        </div>
                        <div data-type="button" className="segment__cell5" onClick={() => segments.removeSegment(segment)}>
                            <DeleteSvg />
                        </div>
                    </div>
                    <div className={classNames({ segment__attachments: true, segment__attachments_open: attachmentIsOpen, segment__attachments_selected: selected })}
                        ref={segmentAttachRef}
                        style={{ maxHeight: attachmentIsOpen ? attachmentsHeight : 0 }}>
                        {attachments.map((attachment, index) => <AttachmentComponent element={segment} attachment={attachment} selectedAttachments={[selectedAttachments, setSelectedAttachments]} key={`attachment_${segment.id}_${attachment.filename}_${index}`} elementId='attachment_segment_' index={index} />)}
                    </div>
                </div >
            )}
        </Draggable >
    );
}
