import { useEffect, useState } from "react";
import * as Listeners from '../../Listeners';
import { useSegments } from "entities/sketch/Segment";


export default function useStatusBar(listener: Listeners.Listener) {
    const [value, setValue] = useState('');
    const selectedElements = useSegments('selectedElements');
    const hideSegments  = useSegments('hideSegments');
    const editingGroup = useSegments('editingGroup');

    useEffect(() => {
        if(listener instanceof Listeners.Select) {
            if (selectedElements.length === 0) return setValue('Indicate objects to define, or select existing to edit')
            if(selectedElements.length === 1) return setValue('Click or draw frame holding Ctrl (Command) to select more, Esc to deselect all.')
            if(selectedElements.length>1) return setValue('Click or draw frame holding Ctrl (Command) to select more, Shift to deselect, Esc to deselect all.')
            // if (selectedElements.length > 1) return setValue('ctrl/Command + click to select multiple, shift + click to remove. "Join" to combine into a single object.');
            else return setValue('ctrl/command+click to select multiple object masks');
        } 
        if(listener instanceof Listeners.BoxListener) return setValue('Frame anything to include in the object mask. Redo to override.');
        if(listener instanceof Listeners.PositivePoint) return setValue('Hover to preview and click anything to add to the mask.');
        if(listener instanceof Listeners.NegativePoint) return setValue('Hover to preview and click anything to exclude from the mask.');
        if(listener instanceof Listeners.BrushListener) return setValue('] to increase, [ to decrease the brush size');
        if(listener instanceof Listeners.EraserListener) return setValue('] to increase, [ to decrease the eraser size');

        if(hideSegments) return setValue('Switch off the hide masks toggle to see the masks.')
        if(editingGroup) return setValue('Click or draw frame holding Ctrl (Command) to select more, Shift to deselect')

        setValue('');
        
      
    }, [editingGroup, hideSegments, listener, selectedElements]);

    return value;
}