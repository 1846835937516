import { useEffect, useMemo, useRef, useState } from 'react';
import { FileData } from 'services/library';
import { ReactComponent as RemoveSvg } from './icons/remove.svg';
import { ReactComponent as ExitSvg } from './icons/exit.svg';
import Input from 'Components/Input';
import { Attachment, Group, Segment, useAttachment } from 'entities/sketch/Segment';
import { useStore } from 'effector-react';
import { $userId } from 'entities/user';
import './Attachment.scss';
import { setHint } from 'entities/hint';


type AttachmentProps = {
    selectedAttachments: [FileData[], React.Dispatch<React.SetStateAction<Attachment[]>>]
    attachment: Attachment;
    element: Segment | Group;
    elementId: string;
    index: number
};

export default function AttachmentComponent({ element, attachment, selectedAttachments: [selectedAttachments, setSelectedAttachments], elementId, index }: AttachmentProps) {
    const userId = useStore($userId);
    const [preview, setPreview] = useState<string | null>(null);
    const { originalname } = attachment;
    const note = useAttachment(attachment, 'note');
    const ext = useMemo(() => originalname.split('.').splice(-1)[0], [originalname]);
    const fileNameBoxRef = useRef<HTMLDivElement>(null);
    const fileNameRef = useRef<HTMLParagraphElement>(null);
    const [fileName, setFileName] = useState<string>(originalname)
    const [isFileNameTruncated, setIsFileNameTruncated] = useState<boolean>(false)

    const fileIsImage = useMemo(() => {
        return ['jpeg', 'jpg', 'png'].includes(ext.toLowerCase());
    }, [ext]);

    useEffect(() => {
        const fileNameDiv = fileNameBoxRef.current;
        const fileNameP = fileNameRef.current
        if (!fileNameDiv || !fileNameP) return
        const oneSymbol = fileNameP.clientWidth / originalname.length
        const maxLength = Math.floor(fileNameDiv.clientWidth / oneSymbol)
        if (originalname.length > maxLength - 8) {
            setFileName(originalname.split('.')[0].substring(0, maxLength - ext.length - 10) + '...' + ext.toLowerCase())
            setIsFileNameTruncated(true)
        }
        else setIsFileNameTruncated(false)

    }, [ext, originalname])


    return (
        <div className="attachment">
            {preview && <Preview src={preview} exit={() => setPreview(null)} />}
            <div />
            <div>
                <div className="attachment__input-container">
                    {
                        fileIsImage
                            ?
                            <img className="attachment__image" alt={''} src={attachment.getImageSrc(userId)} onClick={() => setPreview(attachment.getImageSrc(userId))} />
                            :
                            <div className={`attachment__ext ext-${ext.toLowerCase()}`} />
                    }
                    <Input value={note} onChange={e => attachment.note = e.currentTarget.value} size="table" data-type="input" placeholder="Explain or clarify the attachment" className="attachment__descr" />
                </div>
            </div>
            <div ref={fileNameBoxRef} className="attachment__filename" onClick={() => setPreview(attachment.getImageSrc(userId))}>
                <p ref={fileNameRef} id={`${elementId + index}`}
                    onMouseEnter={() => { if (isFileNameTruncated) setHint({ id: elementId, index: index.toLocaleString(), text: originalname }) }}
                    // onMouseLeave={() => { if (isFileNameTruncated) setHint(null)}}
                >{fileName}</p>
            </div>
            <div className="attachment__remove" onClick={() => element.unattach(attachment)}>
                <RemoveSvg />
            </div>
        </div >
    );
}

type PreviewProps = {
    src: string;
    exit: () => void;
};

function Preview({ src, exit }: PreviewProps) {
    return (
        <div className="attachment-preview">
            <div className="attachment-preview__container">
                <ExitSvg className="attachment-preview__exit-button" onClick={exit} />
                <img className="attachment-preview__image" src={src} alt="preview" />
            </div>
        </div>
    );
}
