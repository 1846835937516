import { createStore, createEffect, createEvent } from "effector";
import * as R from 'ramda';
import { Group, Segment } from "entities/sketch/Segment";
import { FileData, Model, loadModels, upload } from "services/library";

export const $library = createStore<Array<FileData>>([]);
export const $models = createStore<Array<Model>>([]);
export const $elementForAttach = createStore<Segment | Group | null>(null);

export const setElementForAttach = createEvent<Segment | Group | null>();

export const uploadFx = createEffect(upload);
export const loadModelsFx = createEffect(loadModels);

$elementForAttach
    .on(setElementForAttach, (_, value) => value);

$models
    .on(loadModelsFx.doneData, R.nthArg(1));

loadModelsFx();