import { useCallback, useEffect, useState } from 'react';
import { setElementForAttach, uploadFx } from 'entities/library';
import Input from 'Components/Input';
import { Group, Segment, useSegments } from 'entities/sketch/Segment';
import './Url.scss';
import { TextareaAutosize } from '@mui/material';
import classNames from 'classnames';
import { Tab } from '../Attachments';


interface MetaData {
    description: string,
    image: string,
    title: string,
    url: string,
}

type Props = {
    element: Segment | Group;
    setTab: (tab: Tab)=> void;
}

export default function Url({ element, setTab }: Props) {
    const segments = useSegments('self');
    const [url, setUrl] = useState('');
    const [note, setNote] = useState('');
    const [isFocused, setIsFocused] = useState<boolean>(false)


    const [metadata, setMetadata] = useState<MetaData | undefined>(undefined);

    const handleUrlChange = (url: string) => {
        const newUrl = url;
        if (url.trim() === '')
            setMetadata(undefined);
        setUrl(newUrl);
    };


    const handleUploadLink = useCallback(() => {
        if (!url) return;
        const content = `[InternetShortcut]\nURL=${url}`;
        const name = new URL(url).host + '.url';
        const blob = new File([content], name, { type: 'text/plain' });
        uploadFx({ file: blob, note }).then(data => element.attach(data));
        segments.selectOneElement(element);
        setElementForAttach(null);
        setUrl('');
        setNote('');
        setTab(Tab.UPLOAD)
    }, [url, note, segments, element, setTab]);

    useEffect(() => {
        const button = document.getElementById('apply-attachments');
        if (!(button instanceof HTMLButtonElement)) return console.error('Button not found.');
        button.onclick = handleUploadLink;
    }, [handleUploadLink]);

    return (
        <>
            <div className="url-attach__label">URL</div>
            <Input className="url-attach__input"
                placeholder='Paste a URL' value={url}
                onChange={(e) => handleUrlChange(e.target.value)}
            />
            {metadata && <div className='url-attach__metadata'>
                <div className='url-attach__metadata-right-parth'>
                    <div className='title'>{metadata.title}</div>
                    <p>{metadata.description}</p>
                </div>
                 <div className='url-attach__metadata-image'>
               <img src={metadata.image} alt="" />
                </div>
               
            </div>}
            <div className="url-attach__about">CLARIFICATION NOTES</div>
            <div className={classNames({ 'url-attach__note-textarea': true, focus: isFocused })}>
                <TextareaAutosize value={note}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={e => setNote(e.target.value)}
                    className="url-attach__textarea" placeholder="Note" />
            </div>
            {/* <Input className="url-attach__input"
                placeholder="Note" value={note}
                onChange={e => setNote(e.target.value)}
                onBlur={() => undefined}
            /> */}
        </>
    );
}