import EventEmitter from 'eventemitter3';
import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import * as R from 'ramda';
import { $intersection, $segments } from '.';
import * as utils from './utils';
import * as constants from './constants';
import { FileData } from 'services/library';
import addImagePath from './icons/add.svg';
import removeImagePath from './icons/remove.svg';
import { setDrawingTooltip } from 'entities/drawingTooltip';
import { createSketch, saveSketch, renderSketch } from 'services/sketch';
import { $userId } from 'entities/user';
import config from 'config';
import { errorEvent } from 'entities/error';


export type Point = {
    x: number,
    y: number,
    clickType: 1 | 0,
};

export enum AttachmentType {
    CUSTOM = 'CUSTOM',
    MODEL = 'MODEL',
}

type SegmentState = {
    points: Array<Point>;
    box: Array<number> | undefined;
    brush: Array<Array<[number, number, number, number, number, number, number, number]>>;
}

export type Color = {
    r: number;
    g: number;
    b: number;
}

const addImage = new Image();
addImage.src = addImagePath;

const removeImage = new Image();
removeImage.src = removeImagePath;

export type SegmentData = typeof Segment extends new (arg: infer T) => any ? T : never;
export type SegmentDataFromSam = Omit<SegmentData, 'size'>;
export type GroupData = ReturnType<Group['toJSON']>;
export type ListData = { id: string, constructor: 'Group' | 'Segment' };


export type AttachmentProps = FileData & {
    type?: AttachmentType;
};

export class Attachment extends EventEmitter {
    readonly originalname: string;
    readonly filename: string;
    readonly type: AttachmentType;

    #note: string;

    get note() {
        return this.#note;
    }
    set note(value: string) {
        this.#note = value;
        this.emit('note', this.note);
        $segments.getState()?.saveData();
    }

    constructor({ originalname, filename, note, type = AttachmentType.CUSTOM }: AttachmentProps) {
        super();
        this.originalname = originalname;
        this.filename = filename;
        this.#note = note;
        this.type = type;
    }

    public getImageSrc(userId: string): string {
        switch (this.type) {
            case AttachmentType.CUSTOM:
                return `${config.minioUrl}/${config.backet}/${userId}/${this.filename}`;
            case AttachmentType.MODEL:
                return `${config.trainerUrl}/preview-images/${encodeURIComponent(this.originalname)}`;
            default:
                return '';
        }
    }

    public toJSON() {
        return {
            originalname: this.originalname,
            filename: this.filename,
            note: this.#note,
            type: this.type,
        };
    }
}

export class Segment extends EventEmitter {
    readonly id: string;
    readonly outline = new Image();
    readonly boldOutline = new Image();
    readonly image = new Image();

    #native: boolean;
    #points: Array<Point>;
    #description: string;
    #box: Array<number> | undefined;
    #attachments: Array<Attachment>;
    #color: Color;
    #name: number;

    mask: Uint8Array;
    maskSize = 0;
    maskBox = [0, 0, 1, 1];
    groupId: string | null;

    constructor({
        id,
        native,
        points,
        description = '',
        box,
        attachments,
        color,
        name,
        size,
        maskSize,
        groupId,
    }: {
        id: Segment['id'];
        native: Segment['native'];
        points: Segment['points'];
        description: Segment['description'];
        box: Segment['box'];
        attachments: Segment['attachments'];
        color: Segment['color'];
        name: Segment['name'];
        size: Segment['mask']['length'];
        maskSize: Segment['maskSize'];
        groupId: Segment['groupId'],
    }) {
        super();
        this.id = id;
        this.#native = native;
        this.#points = points
        this.#description = description;
        this.#box = box;
        this.#attachments = attachments.map(data => new Attachment(data));
        this.#color = color;
        this.#name = name;
        this.mask = new Uint8Array(size / 8);
        this.maskSize = maskSize;
        this.groupId = groupId;
    }

    get native() {
        return this.#native;
    }
    set native(value: boolean) {
        this.#native = value;
        this.emit('native', this.native);
    }

    get points() {
        return this.#points;
    }
    set points(value: Array<Point>) {
        this.#points = value;
        this.emit('points', this.points);
        $segments.getState()?.saveData();
    }

    get description() {
        return this.#description;
    }
    set description(value: string) {
        this.#description = value;
        this.emit('description', this.description);
        $segments.getState()?.saveData();
    }

    get box() {
        return this.#box;
    }
    set box(box: Array<number> | undefined) {
        this.#box = box;
        this.emit('box', this.box);
        $segments.getState()?.saveData();
    }

    get attachments(): Array<Attachment> {
        return this.#attachments;
    }
    set attachments(value: Array<FileData>) {
        this.#attachments = value.map(data => new Attachment(data));
        this.emit('attachments', this.attachments);
        $segments.getState()?.saveData();
    }

    get color() {
        return this.#color;
    }
    set color(value: Color) {
        this.#color = value;
        this.emit('color', this.color);
        $segments.getState()?.saveData();
    }

    get name() {
        return this.#name;
    }
    set name(value: number) {
        this.#name = value;
        this.emit('name', this.name);
    }

    public setState(state: SegmentState) {
        this.box = state.box;
        this.points = [...state.points];
    }

    public attach(data: AttachmentProps) {
        const attachment = new Attachment(data)
        this.attachments = [...this.#attachments, attachment];
    }

    public unattach(attachment: Attachment) {
        this.attachments = this.#attachments.filter(a => a !== attachment);
    }

    public unattachAll() {
        this.attachments = []
    }

    public async setSrc(src: string) {
        await new Promise((res, rej) => {
            this.image.src = src;
            this.image.crossOrigin = "Anonymous";
            this.image.onload = res;
            this.image.onerror = rej;
        });

        const width = this.image.naturalWidth;
        const height = this.image.naturalHeight;
        const { context } = utils.createCanvasAndContext(width, height);
        context.drawImage(this.image, 0, 0);
        const buffer = context.getImageData(0, 0, width, height).data;
        this.maskSize = 0;
        for (let h = 0; h < height; h++) {
            for (let w = 0; w < width; w++) {
                const index = h * width + w;
                const value = Boolean(buffer[(index) * 4 + 3]);
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;

                if (value) {
                    this.maskSize++;
                    this.mask[byteIndex] |= (1 << bitIndex);
                }
            }
        }
        this.maskBox = this.findBoundingRectangle(this.mask, width, height);
        Promise.all([this.makeOutline(this.outline, 2), this.makeOutline(this.boldOutline, 5)]).catch(() => console.error('Make outline error.'));
    }

    public overMask(x: number, y: number): boolean {
        const index = y * this.image.naturalWidth + x;
        const byteIndex = Math.floor(index / 8);
        const bitIndex = index % 8;

        return (this.mask[byteIndex] & (1 << bitIndex)) !== 0;
    }

    public removePoints(x: number, y: number) {
        const points = this.#points.filter(point => {
            const { width } = point.clickType ? addImage : removeImage;
            const deltaX = point.x - x;
            const deltaY = point.y - y;

            const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
            return distance >= (width / 2);
        });
        if (points.length !== this.#points.length) this.points = points;
    }

    public async changeColor({ r, g, b }: Color) {
        this.color = { r, g, b };
        const { canvas, context } = utils.createCanvasAndContext(this.image.naturalWidth, this.image.naturalHeight);
        context.drawImage(this.image, 0, 0);
        const imageData = context.getImageData(0, 0, this.image.naturalWidth, this.image.naturalHeight);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
            data[i] = r;
            data[i + 1] = g;
            data[i + 2] = b;
        }
        context.putImageData(imageData, 0, 0);
        await new Promise((res, rej) => {
            this.image.src = canvas.toDataURL();
            this.image.onload = res;
            this.image.onerror = rej;
        });
        return this;
    }

    private async makeOutline(image: HTMLImageElement, radius: number) {
        const { canvas, context } = utils.createCanvasAndContext(this.image.naturalWidth, this.image.naturalHeight);
        const outlineData = this.calcContour(this.mask, radius);

        const imageData = new ImageData(outlineData, this.image.naturalWidth, this.image.naturalHeight);
        context.putImageData(imageData, 0, 0);

        await new Promise((res, rej) => {
            image.src = canvas.toDataURL();
            image.onload = res;
            image.onerror = rej;
        });
    }

    private calcContour(mask: Uint8Array, radius: number): Uint8ClampedArray {
        const outlineData = new Uint8ClampedArray(this.image.naturalWidth * this.image.naturalHeight * 4);
        const height = this.image.naturalHeight;
        const width = this.image.naturalWidth;

        function markContour(x: number, y: number): void {
            for (let i = -radius; i <= radius; i++) {
                for (let j = -radius; j <= radius; j++) {
                    const newY = y + i;
                    const newX = x + j;

                    if (newX >= 0 && newX < width && newY >= 0 && newY < height) {
                        const index = newY * width + newX;
                        const byteIndex = Math.floor(index / 8);
                        const bitIndex = index % 8;
                        if ((mask[byteIndex] & (1 << bitIndex)) === 0) {
                            const outlineDataIndex = index * 4;
                            outlineData[outlineDataIndex] = 0;
                            outlineData[outlineDataIndex + 1] = 90;
                            outlineData[outlineDataIndex + 2] = 255;
                            outlineData[outlineDataIndex + 3] = 255;
                        }
                    }
                }
            }
        }

        for (let i = 0; i < height; i++) {
            for (let j = 0; j < width; j++) {
                const index = i * width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((mask[byteIndex] & (1 << bitIndex)) !== 0) {
                    let hasZeroNeighbor = false;

                    for (let ni = -1; ni <= 1; ni++) {
                        for (let nj = -1; nj <= 1; nj++) {
                            const newY = i + ni;
                            const newX = j + nj;

                            if (newX >= 0 && newX < width && newY >= 0 && newY < height) {
                                const index = newY * width + newX;
                                const byteIndex = Math.floor(index / 8);
                                const bitIndex = index % 8;
                                if ((mask[byteIndex] & (1 << bitIndex)) === 0) {
                                    hasZeroNeighbor = true;
                                    break;
                                }
                            }
                        }

                        if (hasZeroNeighbor) {
                            break;
                        }
                    }

                    if (hasZeroNeighbor) {
                        markContour(j, i);
                    }
                }
            }
        }
        return outlineData;
    }

    findBoundingRectangle(mask: Uint8Array, width: number, height: number): Array<number> {
        let y1 = 0,
            y2 = 0,
            x1 = 0,
            x2 = 0;

        // Находим верхнюю границу (y1)
        for (let i = 0; i < height; i++) {
            for (let j = 0; j < width; j++) {
                const index = i * this.image.naturalWidth + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((mask[byteIndex] & (1 << bitIndex)) === 1) {
                    y1 = i;
                    break;
                }
            }
            if (y1 !== 0) break;
        }

        // Находим нижнюю границу (y2)
        for (let i = height - 1; i >= 0; i--) {
            for (let j = 0; j < width; j++) {
                const index = i * this.image.naturalWidth + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((mask[byteIndex] & (1 << bitIndex)) === 1) {
                    y2 = i;
                    break;
                }
            }
            if (y2 !== 0) break;
        }

        // Находим левую границу (x1)
        for (let j = 0; j < width; j++) {
            for (let i = y1; i <= y2; i++) {
                const index = i * this.image.naturalWidth + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((mask[byteIndex] & (1 << bitIndex)) === 1) {
                    x1 = j;
                    break;
                }
            }
            if (x1 !== 0) break;
        }

        // Находим правую границу (x2)
        for (let j = width - 1; j >= 0; j--) {
            for (let i = y1; i <= y2; i++) {
                const index = i * this.image.naturalWidth + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((mask[byteIndex] & (1 << bitIndex)) === 1) {
                    x2 = j;
                    break;
                }
            }
            if (x2 !== 0) break;
        }

        return [x1, y1, x2, y2];
    }

    public toJSON(): SegmentData {
        return {
            points: this.#points,
            description: this.#description,
            box: this.#box,
            attachments: this.#attachments,
            color: this.color,
            name: this.name,
            id: this.id,
            native: this.native,
            size: this.mask.length,
            maskSize: this.maskSize,
            groupId: this.groupId,
        };
    }
}

type GroupProps = {
    segments: Array<Segment>;
    description: string;
    savingDescriptionAndAttachments: boolean;
    attachments: Array<FileData>;
    id?: string;
    name: number;
    included: Array<Segment>;
    state: 'editing' | 'creating' | 'default';
}

class SegmentEditor extends EventEmitter {
    private worker!: Worker;
    readonly canvas: HTMLCanvasElement;
    readonly context: CanvasRenderingContext2D;
    private brushColor: [number, number, number, number] = [0, 0, 0, 0];

    #segment = this.createNewSegment();
    #undo: Array<SegmentState> = [];
    #rendo: Array<SegmentState> = [];
    #editing = false;

    get segment() {
        return this.#segment;
    }
    private set segment(value: Segment) {
        this.#segment = value;
        this.emit('segment', this.segment);
    }

    get undo() {
        return this.#undo;
    }
    private set undo(value: Array<SegmentState>) {
        this.#undo = value;
        this.emit('undo', this.undo);
        this.emit('update');
    }

    get rendo() {
        return this.#rendo;
    }
    private set rendo(value: Array<SegmentState>) {
        this.#rendo = value;
        this.emit('rendo', this.rendo);
    }

    get editing() {
        return this.#editing;
    }
    set editing(value: boolean) {
        const prevState = this.#editing;
        this.#editing = value;
        this.emit('editing', this.editing);
        if (prevState !== this.#editing) this.emit('drawFake');
        if (!value) this.clearData();
    }

    constructor(private readonly width: number, private readonly height: number,) {
        super();

        const { canvas, context } = utils.createCanvasAndContext(width, height);
        this.canvas = canvas;
        this.context = context;
    }

    public async init(id: string) {
        this.worker = new Worker(new URL('./AIWorker.ts', import.meta.url));
        await Promise.all([
            this.initWorker(),
            this.loadEmbedding(id),
        ]);
    }

    public getZoom = (): number => {
        return 1;
    }

    protected calcScale(): number {
        const originalImage = document.getElementById('file');
        const canvas = document.getElementById('canvas');
        if (!canvas) throw new Error('Canvas not found.');
        if (originalImage instanceof HTMLImageElement && canvas instanceof HTMLCanvasElement) return canvas.width / originalImage.naturalWidth;
        else throw new Error('Original image not exist.');
    }

    private async initWorker() {
        await new Promise((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'model') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(null) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
    }

    private async loadEmbedding(id: string) {
        this.worker.postMessage({ type: 'tensor', props: id });
        await new Promise((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'tensor') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(null) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
    }

    public editSegment(segment: Segment) {
        this.segment = segment;
        this.undo = [{ box: segment.box, points: segment.points, brush: [] }];
        this.editing = true;
        this.context.drawImage(this.segment.image, 0, 0);
    }

    public async editNativeSegment(segment: Segment) {
        this.segment = segment;
        this.editing = true;
        this.undo = [];
        this.setBox(segment.maskBox);
        this.action();
    }

    public async cancel() {
        if (this.segment.native) return this.editing = false;
        this.segment.setState(this.undo[0]);
        await this.onnx(this.segment.box, this.segment.points);
        this.emit('update');
        this.editing = false;
    }

    public async endEditing() {
        await this.segment.setSrc(this.canvas.toDataURL());
        await this.segment.changeColor(this.segment.color);
        this.segment.native = false;
        this.editing = false;
    }

    public createNewSegment(): Segment {
        return new Segment({
            id: crypto.randomUUID(),
            native: false,
            points: [],
            description: '',
            box: undefined,
            attachments: [],
            color: utils.generateRandomColor(),
            name: -1,
            size: this.width * this.height,
            maskSize: 0,
            groupId: null,
        });
    }

    private async generate({ box, points, brush }: SegmentState): Promise<void> {
        await this.onnx(box, points);
        const radius = Math.round(9 / this.calcScale() / this.getZoom());
        brush.forEach(action => action.forEach(points => this.brush.drawArea(points, radius)));
    }

    public async undoAction() {
        if (this.#undo.length < 2) return;
        const state = this.#undo.pop();
        if (!state) return;
        this.undo = [...this.#undo];
        this.rendo = [...this.#rendo, state];
        const newState = this.#undo.slice(-1)[0];
        this.segment.setState(newState);
        await this.generate(newState);
        this.emit('update');
    }

    public async rendoAction() {
        const state = this.#rendo.pop();
        if (!state) return;
        this.undo = [...this.#undo, state];
        this.rendo = [...this.#rendo];
        const newState = this.#undo.slice(-1)[0];
        this.segment.setState(newState);
        await this.generate(newState);
        this.emit('update');
    }

    public async addPoint(point: Point) {
        this.segment.points = [...this.segment.points, point];
        this.action();
        await this.onnx(this.segment.box, this.segment.points);
        this.emit('update');
    }

    public async drawPoint(point: Point) {
        await this.onnx(this.segment.box, [...this.segment.points, point]);
        this.emit('update');
    }

    public async setBox(box: Array<number>) {
        const x0x1 = [box[0], box[2]].sort((a, b) => a - b);
        const y0y1 = [box[1], box[3]].sort((a, b) => a - b);
        const integerBox = [x0x1[0], y0y1[0], x0x1[1], y0y1[1]].map(value => Math.round(value));
        this.segment.box = integerBox;
        this.emit('update');
        await this.onnx(this.segment.box, this.segment.points);
        this.emit('update');
    }

    public async removePoints(x: number, y: number) {
        this.segment.removePoints(x, y);
        this.action();
        await this.onnx(this.segment.box, this.segment.points);
        this.emit('update');
    }

    public action(eraser: SegmentState['brush'][0] = []) {
        this.undo = [...this.#undo, { box: this.segment.box, points: this.segment.points, brush: [...this.#undo.slice(-1)[0]?.brush || [], eraser] }];
        this.rendo = [];
    }

    public setBrushColor(color: [number, number, number, number]) {
        this.brushColor = color;
    }

    public brush = this._brush();

    private _brush() {
        let prevPoint: { x: number; y: number } | undefined;
        let brush: SegmentState['brush'][number] = [];

        const drawArea = (points: SegmentState['brush'][number][number], radius: number) => {
            const color = points.slice(4);
            let [x0, y0, x1, y1] = points;
            const dx = Math.abs(x1 - x0);
            const dy = Math.abs(y1 - y0);
            var sx = (x0 < x1) ? 1 : -1;
            var sy = (y0 < y1) ? 1 : -1;
            var err = dx - dy;
            const data = new Uint8ClampedArray(Array.from({ length: radius * radius * 4 * 4 }, (_, index) => color[index % 4]));

            while (true) {
                this.context.putImageData(new ImageData(data, radius * 2, radius * 2,), x0 - radius, y0 - radius);

                if (x0 === x1 && y0 === y1) break;

                var e2 = 2 * err;
                if (e2 > -dy) {
                    err -= dy;
                    x0 += sx;
                }
                if (e2 < dx) {
                    err += dx;
                    y0 += sy;
                }
            }
        }

        const draw = (x: number, y: number, radius: number) => {
            const points: SegmentState['brush'][number][number] = prevPoint ? [prevPoint.x, prevPoint.y, x, y, ...this.brushColor] : [x, y, x, y, ...this.brushColor];
            brush.push(points);
            drawArea(points, radius);

            prevPoint = { x, y };
            this.emit('update');
        };

        const eraserApply = async () => {
            prevPoint = undefined;

            this.action(brush);
            brush = [];
            this.emit('update');
        };

        return { drawArea, eraserApply, draw }
    }

    private clearData() {
        this.#rendo = [];
        this.segment = this.createNewSegment();
        this.#undo = [{ box: this.segment.box, points: this.segment.points, brush: [] }];
        this.context.clearRect(0, 0, this.width, this.height);
    }

    public async updateImage() {
        await this.generate(this.#undo[this.#undo.length - 1]);
        this.emit('update');
    }

    private async generateOnnxImage(box: Array<number> = [], points: Array<Point> = [], color: Color): Promise<void> {
        const LONG_SIDE_LENGTH = 1024;
        const samScale = LONG_SIDE_LENGTH / Math.max(this.height, this.width);
        const positivePoints = points.filter(point => point.clickType);
        const negativePoints = points.filter(point => !point.clickType).map(R.assoc('clickType', 1));
        this.worker.postMessage({ type: 'mask', props: { box, points: positivePoints, height: this.height, width: this.width, samScale, color } });
        const positiveData = await new Promise<any>((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'mask') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(data.result) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
        const positiveArray = new Uint8ClampedArray(positiveData.buffer);
        if (negativePoints.length) {
            this.worker.postMessage({ type: 'mask', props: { box: [], points: negativePoints, height: this.height, width: this.width, samScale, color } });
            const negativeData = await new Promise<any>((res, rej) => {
                const fn = (event: MessageEvent<any>) => {
                    const data = event.data;
                    if (data.type !== 'mask') return;
                    this.worker.removeEventListener('message', fn);
                    data.result ? res(data.result) : rej();
                }

                this.worker.addEventListener('message', fn);
            });
            const negativeArray = new Uint8ClampedArray(negativeData.buffer);
            for (let i = 3; i < positiveArray.length; i += 4) {
                positiveArray[i] = Math.max(0, positiveArray[i] - negativeArray[i]);
            }
        }
        const imageData = new ImageData(positiveArray, positiveData.height, positiveData.width);
        this.context.clearRect(0, 0, this.width, this.height);
        this.context.putImageData(imageData, 0, 0);
    }

    public async generateOnnxImageUrl(box: Array<number> = [], points: Array<Point> = [], color: Color): Promise<string> {
        await this.generateOnnxImage(box, points, color);
        const src = this.canvas.toDataURL();
        this.context.clearRect(0, 0, this.width, this.height);
        return src;
    }

    private onnx = this._onnx();

    private _onnx() {
        let args: [Array<number>, Array<Point>, (value: unknown) => void] | null = null;
        let working = false;
        const fn = (box: Array<number> = [], points: Array<Point>, resolve?: (value: unknown) => void): Promise<any> => {
            return new Promise(async res => {
                if (working) {
                    if (args) args[2](null);
                    return args = [box, points, res];
                }
                working = true;
                await this.generateOnnxImage(box, points, constants.defaultMaskColor);
                if (resolve) resolve(null);
                res(null);
                working = false;
                if (args) {
                    const _args = args;
                    args = null;
                    fn(..._args);
                }
            });
        }

        return fn;
    }
}

export class Group extends EventEmitter {
    readonly id: string;

    #segments: Array<Segment> = [];
    #description: string;
    #attachments: Array<Attachment>;
    #name: number;
    #isOpenForEditing = true;
    #included: Array<Segment>;
    #state: 'editing' | 'creating' | 'default';

    savingDescriptionAndAttachments = false;

    constructor({ segments, description, savingDescriptionAndAttachments, attachments, id = crypto.randomUUID(), name, included, state }: GroupProps) {
        super();
        this.id = id;
        this.#segments = segments;
        this.#segments.forEach(segment => segment.groupId = this.id);
        this.#description = description;
        this.savingDescriptionAndAttachments = savingDescriptionAndAttachments;
        this.#attachments = attachments.map(data => new Attachment(data));
        this.#name = name;
        this.#included = included;
        this.#state = state;
    }

    get segments() {
        return this.#segments;
    }
    private set segments(value: Array<Segment>) {
        this.#segments = value;
        this.emit('segments', this.segments);
        $segments.getState()?.saveData();
    }

    get description() {
        return this.#description;
    }
    set description(value: string) {
        this.#description = value;
        this.emit('description', this.description);
        $segments.getState()?.saveData();
    }


    get attachments(): Array<Attachment> {
        return this.#attachments;
    }
    set attachments(value: Array<FileData>) {
        this.#attachments = value.map(data => new Attachment(data));
        this.emit('attachments', this.attachments);
        $segments.getState()?.saveData();
    }

    get name() {
        return this.#name;
    }
    set name(value: number) {
        this.#name = value;
        this.emit('name', this.name);
        $segments.getState()?.saveData();
    }

    get isOpenForEditing() {
        return this.#isOpenForEditing;
    }
    set isOpenForEditing(value: boolean) {
        this.#isOpenForEditing = value;
        this.emit('isOpenForEditing', this.isOpenForEditing);
    }

    get included() {
        return this.#included;
    }
    set included(value: Array<Segment>) {
        this.#included = value;
        this.emit('included', this.included);
    }

    get state() {
        return this.#state;
    }
    set state(value: 'editing' | 'creating' | 'default') {
        this.#state = value;
        this.emit('state', this.state);
        // $segments.getState()?.saveData();
    }

    public addSegment(segment: Segment, destination: number) {
        /* @ts-ignore */
        this.segments = this.#segments.toSpliced(destination, 0, segment);
        segment.groupId = this.id;
    }

    public changeSegmentPosition(source: number, destination: number) {
        const segments = [...this.#segments];
        const segment = segments.splice(source, 1)[0];
        segments.splice(destination, 0, segment);
        this.segments = segments;
    }

    public removeSegment(segment: Segment) {
        this.segments = this.#segments.filter(s => s !== segment);
        segment.groupId = null;
    }

    public unattach(attachment: FileData) {
        this.attachments = this.#attachments.filter(a => a !== attachment);
    }

    public unattachAll() {
        this.attachments = []
    }

    public attach(attachment: FileData) {
        this.attachments = [...this.#attachments, attachment];
    }

    public toJSON() {
        return {
            id: this.id,
            savingDescriptionAndAttachments: this.savingDescriptionAndAttachments,
            segments: this.#segments.map(({ id }) => id),
            description: this.#description,
            attachments: this.#attachments,
            name: this.#name,
            included: this.#included.map(segment => segment.id),
            state: this.#state,
        };
    }
}

type SegmentsProps = {
    id: string;
    segments: Array<SegmentDataFromSam>;
    file: File;
    list?: Array<ListData>;
    groups?: Array<GroupData>;
    firstLoad: boolean;
    name: string;
};


export class Segments extends EventEmitter {
    segmentEditor!: SegmentEditor;
    scale = 1;
    private selectBox: [number, number, number, number] | null = null;

    #canvas: HTMLCanvasElement | null = null;
    #tutorialCanvas: HTMLCanvasElement;
    #undefinedCanvas: HTMLCanvasElement;
    #fakeCanvas: HTMLCanvasElement;
    #context: CanvasRenderingContext2D | null = null;
    #fakeContext: CanvasRenderingContext2D;
    #tutorialContext: CanvasRenderingContext2D;
    #undefinedContext: CanvasRenderingContext2D;
    #segments: Array<Segment> = [];
    #groups: Array<Group> = [];
    #list: Array<Segment | Group> = [];
    #selectedElements: Array<Segment | Group> = [];
    #label = false;
    #undefinedMode = false;
    #hideSegments = false;
    #id: string;
    #loaded = false;
    #editingGroup: Group | undefined;
    #hoveredElement: Segment | Group | null = null;
    #firstLoad = true;
    #name: string;

    constructor({ id, segments, file, list, groups, firstLoad, name }: SegmentsProps) {
        super();
        this.#id = id;
        this.#name = name;
        this.#firstLoad = firstLoad;
        const { canvas, context } = utils.createCanvasAndContext();
        this.#fakeCanvas = canvas;
        this.#tutorialCanvas = canvas;
        this.#undefinedCanvas = canvas;
        this.#fakeContext = context;
        this.#tutorialContext = context;
        this.#undefinedContext = context;

        this.init(segments, file, list, groups);
    }

    get canvas() {
        return this.#canvas;
    }

    get tutorialCanvas () {
        return this.#tutorialCanvas;
    }

    get undefinedCanvas () {
        return this.#undefinedCanvas;
    }

    get loaded() {
        return this.#loaded;
    }
    set loaded(value: boolean) {
        this.#loaded = value;
        this.emit('loaded', this.loaded);
    }

    get id() {
        return this.#id;
    }

    get segments() {
        return this.#segments;
    }
    set segments(value: Array<Segment>) {
        this.#segments = [...value];
        this.emit('segments', this.segments);
        this.saveData();
    }

    get editingGroup() {
        return this.#editingGroup;
    }
    set editingGroup(value: Group | undefined) {
        if (value) this.segmentEditor.editing = false;
        this.#editingGroup = value;
        this.emit('editingGroup', this.editingGroup);
        this.updateDraw();
    }

    get hoveredElement() {
        return this.#hoveredElement;
    }
    private set hoveredElement(value: Segment | Group | null) {
        this.#hoveredElement = value;
        this.updateDraw();
    }

    get label() {
        return this.#label;
    }
    set label(value: boolean) {
        if (this.segmentEditor.editing && value) return;
        this.#label = value;
        this.emit('label', this.label);
    }

    get undefinedMode() {
        return this.#undefinedMode
    }

    set undefinedMode(value: boolean) {
        this.#undefinedMode = value;
        this.emit('undefinedMode', this.undefinedMode);
    }

    get hideSegments() {
        return this.#hideSegments
    }

    set hideSegments(value: boolean) {
        this.#hideSegments = value;
        this.emit('hideSegments', this.hideSegments);
        this.updateDraw()
    }

    get groups() {
        return this.#groups
    }
    private set groups(value: Array<Group>) {
        this.#groups = value;
        this.emit('groups', this.groups);
        this.saveData();
    }

    get list() {
        return this.#list;
    }
    private set list(value: Array<Segment | Group>) {
        this.#list = value;
        this.emit('list', this.list);
        this.saveData();
    }

    get selectedElements() {
        return this.#selectedElements;
    }
    private set selectedElements(value: Array<Segment | Group>) {
        this.#selectedElements = value;
        this.emit('selectedElements', this.selectedElements);
        this.updateDraw();
    }

    get name() {
        return this.#name;
    }
    set name(value: string) {
        this.#name = value;
        this.emit('name', this.name);
    }

    get self() {
        return this;
    }

    private createNewSegment(): Segment {
        return new Segment({
            id: crypto.randomUUID(),
            native: false,
            points: [],
            description: '',
            box: undefined,
            attachments: [],
            color: utils.generateRandomColor(),
            name: -1,
            size: this.#fakeCanvas.width * this.#fakeCanvas.height,
            maskSize: 0,
            groupId: null,
        });
    }

    public selectElement(value: Segment | Group) {
        this.selectedElements = [...this.#selectedElements, value];
    }

    public selectOneElement(value: Segment | Group) {
        if (this.#editingGroup) return;
        this.selectedElements = [value];
    }

    public selectElements(value: Array<Segment | Group>) {
        this.selectedElements = value;
    }

    public unselectElement(value: Segment | Group) {
        this.selectedElements = this.#selectedElements.filter(segment => segment !== value);
    }

    public unselectAllElements() {
        this.selectedElements = [];
    }

    private async init(segments: Array<SegmentDataFromSam>, file: File, list?: Array<ListData>, groups: Array<GroupData> = []) {
        try {
            await this.loadImageSize(file);
            this.segmentEditor = new SegmentEditor(this.#fakeCanvas.width, this.#fakeCanvas.height);
            await this.segmentEditor.init(this.#id);
            this.segmentEditor.addListener('drawFake', () => this.drawFake());
            this.segmentEditor.addListener('update', () => this.updateDraw());
            await this.initSegments(segments);
            if (this.#firstLoad) this.removeIdenticalMasks();
            this.initGroups(groups);
            this.initList(list);
            if (this.#firstLoad) await this.createSketch();
            this.loaded = true;
            this.drawFake();
            await this.saveSketch();
        } catch (e) {
            console.error('Segments not init.');
            console.error(e);
            errorEvent();
        }
    }

    private initGroups(groups: Array<GroupData>) {
        this.groups = groups.map(group => {
            const segments = group.segments.map(id => {
                const segment = this.#segments.find(segment => segment.id === id);
                if (!segment) throw new Error('Error init groups.');
                return segment;
            });
            return new Group({ ...group, segments, included: [], state: 'default' });
        });
    }

    private initList(list?: Array<ListData>) {
        if (!list) this.list = [...this.#segments];
        else this.list = list.map(element => {
            if (element.constructor === 'Segment') {
                const segment = this.#segments.find(segment => segment.id === element.id);
                if (!segment) throw new Error('Error init list.');
                return segment;
            }
            if (element.constructor === 'Group') {
                const group = this.#groups.find(group => group.id === element.id);
                if (!group) throw new Error('Error init list.');
                return group;
            }
            throw new Error('Error init list.');
        });
    }

    public changeOrder(source: number, destination: number) {
        const list = [...this.#list];
        const element = list.splice(source, 1)[0];
        list.splice(destination, 0, element);
        this.list = list;
    }

    public addSegmentToGroup(segment: Segment, group: Group, destination: number) {
        const alreadyInGroup = this.groups.flatMap(({ segments }) => segments).includes(segment);
        if (alreadyInGroup) return;
        group.addSegment(segment, destination);
        this.list = this.#list.filter(s => s !== segment);
    }

    public removeSegmentFromGroup(segment: Segment, group: Group, destination: number) {
        group.removeSegment(segment);
        /* @ts-ignore */
        this.list = this.#list.toSpliced(destination, 0, segment);
    }

    private initSegments = async (masks: Array<SegmentDataFromSam>) => {
        const initNativeMask = async (mask: SegmentData) => {
            const segment = new Segment(mask);
            await segment.setSrc(`${process.env.REACT_APP_SERVER_URL}/masks/${this.#id}/${mask.name}.png`);
            await segment.changeColor(utils.generateRandomColor());
            return segment;
        }

        const initCustomMasks = async (masks: Array<SegmentData>) => {
            const result: Array<Segment> = [];
            for (const mask of masks) {
                const segment = new Segment(mask);
                result.push(segment);
                const src = await this.segmentEditor.generateOnnxImageUrl(segment.box || [], segment.points, segment.color);
                await segment.setSrc(src);
            }
            return result;
        }

        const nativeMasks: Array<SegmentDataFromSam> = [];
        const customMasks: Array<SegmentDataFromSam> = [];

        masks.forEach(mask => mask.native ? nativeMasks.push(mask) : customMasks.push(mask));

        this.segments = (await Promise.all([...nativeMasks.map(mask => initNativeMask({ ...mask, size: this.#fakeCanvas.width * this.#fakeCanvas.height })), initCustomMasks(customMasks.map(mask => ({ ...mask, size: this.#fakeCanvas.width * this.#fakeCanvas.height })))])).flatMap(item => item);
    }

    private loadImageSize = async (file: File) => {
        const image = new Image();
        await new Promise((res, rej) => {
            image.src = URL.createObjectURL(file);
            image.onload = res;
            image.onerror = rej;
        });

        this.#fakeCanvas.width = image.naturalWidth;
        this.#fakeCanvas.height = image.naturalHeight;
    }

    private removeIdenticalMasks() {
        this.#segments.forEach((segment, index) => {
            let removedCount = 0;
            for (let i = index + 1; i < this.#segments.length; i++) {
                let intersectionCount = 0;

                for (let j = 0; j < segment.mask.length; j++) {
                    let and = segment.mask[j] & this.#segments[i - removedCount].mask[j];

                    while (and) {
                        intersectionCount += and & 1;
                        and >>= 1;
                    }
                }

                const intersectionPercentage = intersectionCount / segment.maskSize * 100;
                if (intersectionPercentage > $intersection.getState()) {
                    this.#segments.splice(i - removedCount, 1);
                    removedCount++;
                }
            }
        });
    }

    public connectCanvas(canvas: HTMLCanvasElement) {
        const context = canvas.getContext('2d');
        if (!context) throw new Error('Context not found.');
        this.#canvas = canvas;
        this.#context = context;
        const resizeObserver = new ResizeObserver(() => this.updateDraw());
        resizeObserver.observe(this.#canvas);
    }

    public connectTutorialCanvas(canvas: HTMLCanvasElement, tutorialStep: number) {
        const context = canvas.getContext('2d');
        if (!context) throw new Error('Context not found.');
        this.#tutorialCanvas = canvas;
        this.#tutorialContext = context;
        const resizeObserver = new ResizeObserver(() => this.updateTutorialDraw(tutorialStep));
        resizeObserver.observe(this.#tutorialCanvas);
    }

    public connectUndefinedCanvas(canvas: HTMLCanvasElement) {
        const context = canvas.getContext('2d');
        if (!context) throw new Error('Context not found.');
        this.#undefinedCanvas = canvas;
        this.#undefinedContext = context;
        const resizeObserver = new ResizeObserver(() => this.updateUndefinedDraw());
        resizeObserver.observe(this.#undefinedCanvas);
    }


    public saveData() {
        if (this.#list.length === 0) return;
        const list = this.#list.map(element => {
            let constructor = '';
            if (element instanceof Segment) constructor = 'Segment';
            if (element instanceof Group) constructor = 'Group';
            return ({ id: element.id, constructor });
        });

        localStorage.setItem('sketch_data', JSON.stringify({ id: this.id, list, segments: this.#segments, groups: this.#groups, userId: $userId.getState(), firstLoad: this.#firstLoad, name: this.#name }));
    }

    public updateDraw() {
        if (!this.#context || !this.#canvas) return;
        if(this.#undefinedMode && (!this.#undefinedContext || !this.undefinedCanvas)) return;
        const { width, height } = this.#canvas;
        this.#context.clearRect(0, 0, width, height);

        if(this.#undefinedMode ) {
            this.#undefinedContext.clearRect(0, 0, width, height)
            this.updateUndefinedDraw()
            const context = this.#context;
            const segments = this.#segments;
            const groups = this.#groups;
            segments.forEach(segment => {
                if ((segment.description !== '' || segment.attachments.length !== 0) && !segment.groupId){
                    context.drawImage(segment.image, 0, 0, width, height)  
                }
                else {
                    const group = groups.find((group) => group.id === segment.groupId)
                    if (group && ((group.description !== '' &&  group.description !== 'Group description: ') || group.attachments.length !== 0)) {
                        context.drawImage(segment.image, 0, 0, width, height)
                    }
                }
            })
        } 
        else if(this.#hideSegments) {}
        else this.#context.drawImage(this.#fakeCanvas, 0, 0, width, height);

        if (this.segmentEditor.editing) {
            this.#context.drawImage(this.segmentEditor.canvas, 0, 0, width, height);
            this.drawPrimitives(this.segmentEditor.segment);
        } 
        else if(!this.#hideSegments) {
            let context = this.#context;
             if(this.#undefinedMode) context = this.#undefinedContext
    
            if (this.#hoveredElement && !this.#selectedElements.includes(this.#hoveredElement)) {
                if (this.#hoveredElement instanceof Segment) context.drawImage(this.#hoveredElement.boldOutline, 0, 0, width, height);
                if (this.#hoveredElement instanceof Group) this.#hoveredElement.segments.forEach(segment => context.drawImage(segment.boldOutline, 0, 0, width, height));
            }
            this.#selectedElements.forEach(element => {
                if (element instanceof Segment) context.drawImage(element.outline, 0, 0, width, height);
                if (element instanceof Group) element.segments.forEach(segment => context.drawImage(segment.outline, 0, 0, width, height));
            });
        }
        if (this.selectBox) {
            this.#context.beginPath();
            const [x, y, x1, y1] = this.selectBox.map(value => value * this.scale);
            this.#context.rect(x, y, x1 - x, y1 - y);
            this.#context.strokeStyle = '#0463E1';
            this.#context.lineWidth = 1;
            this.#context.stroke();
        }
    }

    public updateTutorialDraw(tutorialStep: number) {
        if (!this.#tutorialContext || !this.#tutorialCanvas) return;
        const { width, height } = this.#tutorialCanvas;
        this.#tutorialContext.clearRect(0, 0, width, height);
        const context = this.#tutorialContext;
        this.#selectedElements.forEach((element) => {
          if (element instanceof Segment) {
            if (tutorialStep === 1) {
              context.drawImage(element.outline, 0, 0, width, height);
            } else {
              context.drawImage(element.outline, 0, 0, width, height);
              context.drawImage(element.image, 0, 0, width, height);
            }
          }
          if (element instanceof Group)
            element.segments.forEach((segment) => {
              context.drawImage(segment.outline, 0, 0, width, height);
              context.drawImage(segment.image, 0, 0, width, height);
            });
        });
      }

    public updateUndefinedDraw() {
        if (!this.#undefinedContext || !this.#undefinedCanvas) return;
        const { width, height } = this.#undefinedCanvas;
        this.#undefinedContext.clearRect(0, 0, width, height);
        const segments = this.#segments;
        const groups = this.#groups;
     
        segments.forEach(segment => {
            if (segment.description === '' && segment.attachments.length === 0 && !segment.groupId)
                this.#undefinedContext.drawImage(segment.image, 0, 0, width, height)
            else {
                const group = groups.find((group) => group.id === segment.groupId)
                if (group && (group.description === '' || group.description === 'Group description: ') && group.attachments.length === 0) {
                    this.#undefinedContext.drawImage(segment.image, 0, 0, width, height)
                }
            }
        });
    }
    

    public drawFake() {
        const segments = this.segmentEditor.editing ? this.#segments.filter(segment => this.segmentEditor.segment !== segment) : this.#segments;
        const { width, height } = this.#fakeCanvas;
        this.#fakeContext.clearRect(0, 0, width, height);
        segments.forEach(segment => this.#fakeContext.drawImage(segment.image, 0, 0, width, height));
        if (this.segmentEditor.editing) this.blackAndWhite();
        this.updateDraw();
    }

    private blackAndWhite() {
        const imageData = this.#fakeContext.getImageData(0, 0, this.#fakeCanvas.width, this.#fakeCanvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
            const brightness = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2];
            data[i] = brightness;
            data[i + 1] = brightness;
            data[i + 2] = brightness;
        }
        this.#fakeContext.putImageData(imageData, 0, 0);
    }

    public createGroup(segments: Array<Segment>, { including = false, saving = false }: { including?: boolean; saving?: boolean }, { state = 'default' }: { state?: 'editing' | 'creating' | 'default' }) {
        const list = [...this.#list];
        const [index, ...indexes] = this.list.reduce<Array<number>>((acc, cur, index) => {
            if (!segments.includes(cur as any)) return acc;
            acc.push(index);
            return acc;
        }, []).sort((a, b) => a - b);
        this.unselectAllElements();
        const name = this.#list.sort(({ name: a }, { name: b }) => a - b).slice(-1)[0].name + 1;
        const group = new Group({ segments, description: 'Group description: ', savingDescriptionAndAttachments: saving, attachments: [], name, included: !including ? [...segments] : [], state: state });
        list.splice(index, 1, group);
        indexes.reverse().forEach(index => list.splice(index, 1));
        this.groups = [...this.#groups, group];
        this.list = list;
        setTimeout(() => {
            const element = document.getElementById('element_' + group.id);
            element?.scrollIntoView({ block: 'center' });
        }, 10);
        return group;
    }

    public async changeToRandomColor(segment: Segment) {
        await segment.changeColor(utils.generateRandomColor());
        this.drawFake();
    }

    public ungroup(groups: Array<Group>) {
        groups.forEach(group => {
            const list = [...this.#list];
            const index = list.indexOf(group);
            list.splice(index, 1, ...group.segments);
            this.groups = this.#groups.filter(g => g !== group);
            this.list = list;
            group.segments.forEach(segment => segment.groupId = null);
        });
    }

    public labelFns = (() => {
        let timer: null | NodeJS.Timeout = null;
        let currentSegment: Segment | null = null;

        const showLabel = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>, scale: number) => {
            if (timer) clearTimeout(timer);
            const fn = () => {
                const x = e.nativeEvent.offsetX;
                const y = e.nativeEvent.offsetY;
                /* @ts-ignore */
                const segment = this.#segments.toReversed().find((segment: Segment) => segment.overMask(Math.floor(x / scale), Math.floor(y / scale)));
                if (!segment) return;
                if (segment === currentSegment) return;
                currentSegment = segment;
                setDrawingTooltip(null);
                setDrawingTooltip({
                    segment,
                    left: e.clientX + 5,
                    top: e.clientY - 5,
                    opacity: 0,
                });
            };

            timer = setTimeout(fn, 150);
        }

        function hideLabel(e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) {
            const x = e.clientX;
            const y = e.clientY;
            const tooltip = document.getElementById('drawing-tootip');
            const canvas = document.getElementById('canvas');
            if (!tooltip || !canvas) return;
            const elementUnderCursor = document.elementsFromPoint(x, y);
            if (elementUnderCursor.includes(tooltip) || elementUnderCursor.includes(canvas)) return;
            if (timer) clearTimeout(timer);
            timer = null;
            currentSegment = null;
            setDrawingTooltip(null);
        }

        return { showLabel, hideLabel };
    })()

    private drawPrimitives(segment: Segment) {
        if (!this.#context) return;
        const context = this.#context;
        segment.points.forEach(point => {
            switch (point.clickType) {
                case 1:
                    const { width: aWidth, height: aHeight } = addImage;
                    context.drawImage(addImage, (point.x - aWidth / 2) * this.scale, (point.y - aHeight / 2) * this.scale, aWidth * this.scale, aHeight * this.scale);
                    break;
                case 0:
                    const { width: rWidth, height: rHeight } = removeImage;
                    context.drawImage(removeImage, (point.x - rWidth / 2) * this.scale, (point.y - rHeight / 2) * this.scale, rWidth * this.scale, rHeight * this.scale);
                    break;
                default: console.error('Unknown click type.');
            }
        });

        if (segment.box) {
            this.#context.beginPath();
            const [x, y, x1, y1] = segment.box.map(value => value * this.scale);
            this.#context.rect(x, y, x1 - x, y1 - y);
            this.#context.strokeStyle = '#0463E1';
            this.#context.lineWidth = 1;
            this.#context.stroke();
        }
    }

    public editSegment(segment: Segment) {
        this.label = false;
        if (segment.native) this.segmentEditor.editNativeSegment(segment);
        else this.segmentEditor.editSegment(segment);
    }

    public removeSegment(segment: Segment) {
        if (segment === this.#hoveredElement) this.hoveredElement = null;
        this.selectedElements = this.#selectedElements.filter(element => element !== segment);
        this.list = this.#list.filter(s => s !== segment);
        this.segments = this.#segments.filter(s => s !== segment);
        this.groups.forEach(group => {
            if (group.segments.includes(segment)) group.removeSegment(segment);
        });
        this.selectedElements = [];
        this.drawFake();
    }

    public doneEditingGroup(description: string, attachments: Attachment[]) {
        const group = this.editingGroup;
        if (!group) return console.error('Group is not being edited.');
        group.description = description;
        group.attachments = attachments;
        group.included = [];
        group.segments.forEach(segment => {
            segment.attachments = [];
            segment.description = '';
        });
        this.editingGroup = undefined;
    }

    public canselCreatingGroup() {
        const group = this.editingGroup;
        if (!group) return console.error('Group is not being edited.');
        group.description = '';
        group.attachments = [];
        group.included = [];
        this.editingGroup = undefined;
    }

    public cancelEditingGroup() {
        const group = this.editingGroup;
        if (!group) return console.error('Group is not being edited.');
        // group.description = [group.description, ...group.included.map(segment => segment.description)].filter(Boolean).join(', ');
        // group.attachments = [...new Set([...group.attachments, ...group.included.flatMap(segment => segment.attachments)])];
        // group.included = [];
        // group.segments.forEach(segment => {
        //     segment.attachments = [];
        //     segment.description = '';
        // });
        this.editingGroup = undefined;
    }


    public setSelectBox(value: [number, number, number, number] | null): void {
        this.selectBox = value;
        this.updateDraw();
    }

    public selectSegmentsWithBox() {
        if (!this.selectBox) throw new Error('Select box is not exist.');
        const selectBox = this.selectBox.map(Math.round);
        const width = this.#fakeCanvas.width;
        const height = this.#fakeCanvas.height;
        const rectMask = new Uint8Array(width * height / 8);
        for (let h = selectBox[1]; h <= selectBox[3]; h++) {
            for (let w = selectBox[0]; w <= selectBox[2]; w++) {
                const index = h * width + w;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;

                rectMask[byteIndex] |= (1 << bitIndex);
            }
        }

        const intersected = this.segments.filter(segment =>
            !(segment.maskBox[0] > selectBox[2] ||
                segment.maskBox[2] < selectBox[0] ||
                segment.maskBox[1] > selectBox[3] ||
                segment.maskBox[3] < selectBox[1])
        ).filter(segment => {
            let intersection = 0;
            for (let i = 0; i < rectMask.length; i++) {
                let and = rectMask[i] & segment.mask[i];

                while (and) {
                    intersection += and & 1;
                    and >>= 1;
                }
            }

            return intersection / segment.maskSize > 0.8;
        });

        this.selectElements(intersected);
    }

    public removeGroup(group: Group) {
        this.list = this.#list.filter(g => g !== group);
        group.segments.forEach(segment => this.removeSegment(segment));
        this.groups = this.#groups.filter(g => g !== group);
    }

    private async createSketch() {
        this.#firstLoad = false;
        this.saveData();
        const data = localStorage.getItem('sketch_data');
        if (!data) throw new Error('Data is null.');

        await createSketch(data);
    }

    public async saveSketch() {
        const data = localStorage.getItem('sketch_data');
        if (!data) throw new Error('Data is null.');
        const sketch = await new Promise<Blob | null>(res => this.#fakeCanvas.toBlob(res, 'image/png'));
        if (!sketch) throw new Error('Sketch is null.');

        await saveSketch(data, sketch, this.#id);
    }

    public async renderSketch(settings: string) {
        await this.saveSketch();
        const sketch = await new Promise<Blob | null>(res => this.#fakeCanvas.toBlob(res, 'image/png'));
        if (!sketch) throw new Error('Sketch is null.');
        await Promise.all(this.#segments.map(segment => segment.changeColor({ r: 0, g: 160, b: 80 })));
        const masks = await Promise.all(this.#segments.map(segment => fetch(segment.image.src).then(res => res.blob()).then(mask => ({ mask, id: segment.id }))));
        const data = localStorage.getItem('sketch_data');
        if (!data) throw new Error('Data is null.');

        await renderSketch(sketch, masks, data, settings, crypto.randomUUID());
    }

    public hoverSegment = this._hoverSegment();

    private _hoverSegment() {
        let prevSegments: Array<Segment> = [];
        return (x: number, y: number) => {
            const segments = this.#segments.filter((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y)));
            if (prevSegments.length === segments.length) {
                const diff = segments.filter(segment => !prevSegments.includes(segment));
                if (diff.length === 0) return;
            }
            prevSegments = segments;
            /* @ts-ignore */
            const segment = this.#segments.toReversed().find((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y)));
            if (!segment) return this.hoveredElement = null;
            const element = this.#groups.find(({ segments }) => segments.includes(segment)) || segment;

            if (this.#undefinedMode) {
                this.hoveredElement = (element instanceof Group ?
                    ((element.description !== '' && element.description !== 'Group description: ') || element.attachments.length !== 0) :
                    (element.description !== '' || element.attachments.length !== 0)) ? null : element;
            } 
            else if(this.#hideSegments)   this.hoveredElement = null
            else this.hoveredElement = element;
            
        }
    }

    public tabHover(x: number, y: number) {
        if (!this.#hoveredElement) return;
        /* @ts-ignore */
        const segments = this.#segments.filter((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y))).toReversed();

        const index = segments.findIndex((segment: Segment) => {
            if (this.#hoveredElement instanceof Segment) return segment === this.#hoveredElement;
            else return this.#hoveredElement?.segments.includes(segment);
        });
        const segment = segments[(index + 1) % segments.length];
        const element = this.#groups.find(({ segments }) => segments.includes(segment)) || segment;
        this.hoveredElement = element;
        if (this.#undefinedMode) {
            this.hoveredElement = (element instanceof Group ?
                ((element.description !== '' && element.description !== 'Group description: ') || element.attachments.length !== 0) :
                (element.description !== '' || element.attachments.length !== 0)) ? null : element;
        } 
        else if(this.#hideSegments) this.hoveredElement = null
        else this.hoveredElement = element;
    }

    public unhoverSegment() {
        this.hoveredElement = null;
    }

    public async createSegment() {
        const segment = this.segmentEditor.segment;
        segment.name = (this.#list.sort(({ name: a }, { name: b }) => a - b).slice(-1)[0]?.name || -1) + 1;

        this.list = [...this.#list, segment];
        await this.segmentEditor.endEditing();
        this.segments = [...this.#segments, segment].sort(({ maskSize: a }, { maskSize: b }) => b - a);
        this.drawFake();
    }

    public async endEditing() {
        await this.segmentEditor.endEditing();
        this.segments = this.#segments.sort(({ maskSize: a }, { maskSize: b }) => b - a);
    }

    public async combineSegments(segments: Array<Segment>, including: boolean) {
        if (!segments.length) throw new Error('Need 2 or more segments.');
        const { canvas, context } = utils.createCanvasAndContext(this.#fakeCanvas.width, this.#fakeCanvas.height);
        segments.forEach(segment => context.drawImage(segment.image, 0, 0));

        const segment = this.createNewSegment();
        if (including) {
            segment.description = segments.map(({ description }) => description).filter(Boolean).join(', ');
            segment.attachments = [...new Set(segments.flatMap(segment => segment.attachments))];
        }

        const firstIndex = this.#list.findIndex(element => segments.includes(element as Segment));

        this.segments = this.#segments.filter(segment => !segments.includes(segment));
        this.list = this.#list.filter(element => !segments.includes(element as Segment));
        segment.name = this.list.sort(({ name: a }, { name: b }) => a - b).slice(-1)[0].name + 1;
        await segment.setSrc(canvas.toDataURL());
        await segment.changeColor(utils.generateRandomColor());
        this.segments = [...this.#segments, segment].sort(({ maskSize: a }, { maskSize: b }) => b - a);
        /* @ts-ignore */
        this.list = this.#list.toSpliced(firstIndex, 0, segment);
        setTimeout(() => {
            const element = document.getElementById('element_' + segment.id);
            element?.scrollIntoView({ block: 'center' });
        }, 10);
        this.drawFake();
        return segment;
    }

    public async editGroupHandler(x: number, y: number, adding: boolean) {
        if (!this.#editingGroup) return;
        /* @ts-ignore */
        const segment = this.#segments.toReversed().find(segment => segment.overMask(Math.floor(x), Math.floor(y)));
        if (!segment) return false;
        const included = this.#editingGroup.segments.includes(segment);
        if (included) {
            if (adding) return;
            this.#editingGroup.removeSegment(segment);
            this.list = [...this.#list, segment];
        } else {
            if (!adding) return;
            const alreadyInGroup = this.groups.flatMap(({ segments }) => segments).includes(segment);
            if (alreadyInGroup) return;
            this.#editingGroup.addSegment(segment, 0);
            this.list = this.#list.filter(s => s !== segment);
        }

        this.updateDraw();
    }
}

export function useAttachment<T extends keyof Pick<Attachment, 'note'>>(attachment: Attachment, key: T) {
    const [value, setValue] = useState(attachment[key]);

    useEffect(() => {
        setValue(attachment[key]);
        attachment.addListener(key, setValue);
        return () => void attachment.removeListener(key, setValue);
    }, [attachment, key]);

    return value;
}

export function useSegment<T extends keyof Pick<Segment, 'description' | 'name' | 'attachments'>>(segment: Segment, key: T) {
    const [value, setValue] = useState(segment[key]);

    useEffect(() => {
        setValue(segment[key]);
        segment.addListener(key, setValue);
        return () => void segment.removeListener(key, setValue);
    }, [segment, key]);

    return value;
}

export function useGroup<T extends keyof Pick<Group, 'segments' | 'description' | 'attachments' | 'name' | 'isOpenForEditing'>>(group: Group, key: T) {
    const [value, setValue] = useState(group[key]);

    useEffect(() => {
        group.addListener(key, setValue);
        return () => void group.removeListener(key, setValue);
    }, [group, key]);

    return value;
}

export function useEditor<T extends keyof Pick<SegmentEditor, 'editing' | 'undo' | 'rendo' | 'segment'>>(key: T) {
    const editor = useSegments('segmentEditor');
    const [value, setValue] = useState(editor[key]);

    useEffect(() => {
        editor.addListener(key, setValue);
        return () => void editor.removeListener(key, setValue);
    }, [editor, key]);

    return value;
}

export function useSegments<T extends keyof Pick<Segments, 'segments' | 'loaded' | 'groups' | 'self' | 'list' | 'label' | 'undefinedMode' | 'hideSegments' | 'editingGroup' | 'selectedElements' | 'segmentEditor' | 'name'>>(key: T) {
    const segments = useStore($segments);
    if (!segments) throw new Error('Segments is undefined.');
    const [value, setValue] = useState(segments[key]);

    useEffect(() => {
        if (!segments) return;
        setValue(segments[key]);
        segments.addListener(key, setValue);
        return () => void segments.removeListener(key, setValue);
    }, [segments, key]);

    return value;
}
