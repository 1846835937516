import { createStore } from "effector";
import { Segment } from "entities/sketch/Segment";

export type DrawingTooltipProps = {
    segment: Segment;
    left: number;
    top: number;
    opacity: 0;
}

export const $drawingTooltip = createStore<DrawingTooltipProps | null>(null);