import { Group, Segments } from 'entities/sketch/Segment';
import { defaultMaskColor } from 'entities/sketch/constants';


export class Listener {
    constructor(protected readonly segments: Segments) {
        segments.unhoverSegment();
    }
    public onMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onMouseUp(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onDoubleClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onContextMenu(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onMouseMoveCapture(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        this.segments.labelFns.showLabel(e, this.calcScale());
    }
    public onMouseLeave(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        this.segments.labelFns.hideLabel(e);
    }
    public boxStart(e: React.MouseEvent<HTMLDivElement, MouseEvent>) { }
    public boxMove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) { }

    protected calcScale(): number {
        const originalImage = document.getElementById('file');
        const canvas = this.segments.canvas;
        if (!canvas) throw new Error('Canvas not exist.');
        if (originalImage instanceof HTMLImageElement) return canvas.width / originalImage.naturalWidth;
        else throw new Error('Original image not exist.');
    }

    public setGetZoom(fn: () => number) {
        this.getZoom = fn;
    }

    protected getZoom() {
        return 1;
    }
}

export class Zoom extends Listener { }

export abstract class Point extends Listener {
    protected abstract type: 0 | 1;

    public async onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const scale = this.calcScale();
        await this.segments.segmentEditor.drawPoint({ x: e.nativeEvent.offsetX / scale, y: e.nativeEvent.offsetY / scale, clickType: this.type })
    };

    public onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const scale = this.calcScale();
        this.segments.segmentEditor.addPoint({ x: e.nativeEvent.offsetX / scale, y: e.nativeEvent.offsetY / scale, clickType: this.type });
    }

    public async onMouseLeave(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        super.onMouseLeave(e);
        await this.segments.segmentEditor.updateImage();
    }
}

export class PositivePoint extends Point {
    type: 1 = 1;
}

export class NegativePoint extends Point {
    type: 0 = 0;
}

export class BoxListener extends Listener {
    private beginX = 0;
    private beginY = 0;
    private drawing = false;

    private calcPoint(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const element = document.getElementById('canvas');
        if (!element) throw new Error('Canvas not found.');

        const rect = element.getBoundingClientRect();

        const scale = this.calcScale();
        const zoom = this.getZoom();
        const distanceX = e.clientX - (rect.left + window.scrollX);
        const distanceY = e.clientY - (rect.top + window.scrollY);

        const x = Math.max(Math.min(element.offsetWidth * zoom, distanceX), 1) / scale / zoom;
        const y = Math.max(Math.min(element.offsetHeight * zoom, distanceY), 1) / scale / zoom;
        return { x, y };
    }

    public boxStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { x, y } = this.calcPoint(e);
        this.beginX = x;
        this.beginY = y;
        this.drawing = true;
    }

    public boxMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!this.drawing) return;
        const { x, y } = this.calcPoint(e);
        this.segments.segmentEditor.setBox([this.beginX, this.beginY, x, y]);
    }

    public onMouseUp = () => {
        if (!this.drawing) return;
        this.segments.segmentEditor.action();
        this.beginX = 0;
        this.beginY = 0;
        this.drawing = false;
    }

    public onMouseLeave(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
        if (!this.drawing) return;
        const originalImage = document.getElementById('file');
        if (originalImage instanceof HTMLImageElement) {
            const scale = this.calcScale();
            const offsetX = Math.min(Math.max(0, e.nativeEvent.offsetX) / scale, originalImage.naturalWidth - 1);
            const offsetY = Math.min(Math.max(0, e.nativeEvent.offsetY) / scale, originalImage.naturalHeight - 1);
            this.segments.segmentEditor.setBox([this.beginX, this.beginY, offsetX, offsetY]);
        }
    }
}

export abstract class BrushToolListener extends Listener {
    private radius = 9;

    public onMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        this.onMouseMove(e);
    }

    public onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        if (e.buttons !== 1) return;
        const scale = this.calcScale();
        const zoom = this.getZoom();
        this.segments.segmentEditor.brush.draw(Math.round(e.nativeEvent.offsetX / scale), Math.round(e.nativeEvent.offsetY / scale), Math.round(this.radius / scale / zoom));
    }

    public onMouseUp() {
        this.segments.segmentEditor.brush.eraserApply();
    }

    public onMouseLeave(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
        if (e.buttons !== 1) return;
        const originalImage = document.getElementById('file');
        if (originalImage instanceof HTMLImageElement) {
            const scale = this.calcScale();
            const zoom = this.getZoom();
            this.segments.segmentEditor.brush.draw(Math.round(e.nativeEvent.offsetX / scale), Math.round(e.nativeEvent.offsetY / scale), Math.round(this.radius / scale / zoom));
            this.segments.segmentEditor.brush.eraserApply();
        }
    }
}

export class EraserListener extends BrushToolListener {
    constructor(segments: Segments) {
        super(segments);
        segments.segmentEditor.setBrushColor([0, 0, 0, 0]);
    }
}

export class BrushListener extends BrushToolListener {
    constructor(segments: Segments) {
        super(segments);
        segments.segmentEditor.setBrushColor(Object.values(defaultMaskColor) as [number, number, number, number]);
    }
}

export class Select extends Listener {
    private overDrawing = false;
    private selectBoxBegin: [number, number] | null = null;
    private x = 0;
    private y = 0;


    public onMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
        if (e.altKey) {
            const scale = this.calcScale();
            this.selectBoxBegin = [e.nativeEvent.offsetX / scale, e.nativeEvent.offsetY / scale];
        }
    }

    public onMouseUp(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
        if (e.altKey && this.selectBoxBegin) {
            this.segments.selectSegmentsWithBox();
            this.segments.setSelectBox(null);
            this.selectBoxBegin = null;
        }
    }

    public onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        if (e.altKey) return;
        const segment = this.segments.hoveredElement;
        if (!segment) return this.segments.unselectAllElements();
        if (e.shiftKey) return this.segments.unselectElement(segment);
        const element = document.getElementById('element_' + segment.id);
        element?.scrollIntoView({ block: 'center', behavior: 'smooth'});
        if (e.ctrlKey || e.metaKey) return this.segments.selectElement(segment);
        return this.segments.selectOneElement(segment);
    }

    public onDoubleClick(): void {
        const element = this.segments.hoveredElement;
        if (!element || element instanceof Group) return;
        this.segments.editSegment(element);
    }

    public onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        this.overDrawing = true;
        const scale = this.calcScale();
        this.x = e.nativeEvent.offsetX / scale;
        this.y = e.nativeEvent.offsetY / scale;
        if (!e.altKey) this.selectBoxBegin = null;
        if (this.selectBoxBegin) this.segments.setSelectBox([...this.selectBoxBegin, this.x, this.y]);
        else this.segments.hoverSegment(this.x, this.y);
    }

    public onMouseLeave(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
        this.overDrawing = false;
        super.onMouseLeave(e);
        this.segments.unhoverSegment();
    }

    public handleTab() {
        if (!this.overDrawing) return;
        this.segments.tabHover(this.x, this.y);
    }
}

export class SelectForGroup extends Select {
    public async onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): Promise<void> {
        const group = this.segments.editingGroup;
        if (!group) throw new Error('Group is not being edited.')
        const element = this.segments.hoveredElement;
        if (!element || element instanceof Group) return;
        if (!e.ctrlKey && !e.metaKey) return;
        if (group.segments.includes(element)) this.segments.removeSegmentFromGroup(element, group, 0);
        else this.segments.addSegmentToGroup(element, group, group.segments.length);
    }
    public onDoubleClick(): void { }
}

export class PointRemover extends Listener {
    public onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const scale = this.calcScale();
        this.segments.segmentEditor.removePoints(e.nativeEvent.offsetX / scale, e.nativeEvent.offsetY / scale);
    }
}

export class EditGroup extends Listener {
    public onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const scale = this.calcScale();
        let adding: boolean | null = null;
        if (e.ctrlKey) adding = true;
        if (e.shiftKey) adding = false;
        if (e.ctrlKey && e.shiftKey) adding = null;
        if (adding === null) return;
        this.segments.editGroupHandler(e.nativeEvent.offsetX / scale, e.nativeEvent.offsetY / scale, adding);
    }
}
