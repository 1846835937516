import { useEffect, useState } from 'react';
import * as Listeners from './Listeners';
import Drawing from './Drawing';
import LeftBar from './LeftBar';
import List from './List';
import Tutorial from './Tutorial/Tutorial';
import useStyles from './useStyles';
import { useSegments } from 'entities/sketch/Segment';
import Header from 'Components/Header';
import MobileUpload from 'pages/Upload/Mobile/MobileUpload';
import './Sketch.scss';
import { useStore } from 'effector-react';
import { $tutorial, $tutorialDisabled, setPrevSketchState, setTutorialDisabled, skip, TutorialDisabledReasons } from 'entities/tutorial';


export default function Sketch() {
    const segments = useSegments('self');
    const list = useSegments('list')
    const segmentEditor = useSegments("segmentEditor")
    const [listener, setListener] = useState<Listeners.Listener>(() => new Listeners.Select(segments));
    const tutorial = useStore($tutorial);
    const [showTutorial, setShowTutorial] = useState<boolean>(false)
    const tutorialDisabled = useStore($tutorialDisabled)

    useStyles();

    useEffect(() => {

        if (list.length < 3 || segmentEditor.editing) {
            if (list.length < 3) setTutorialDisabled(TutorialDisabledReasons.NO_ELEMENTS)
            else setTutorialDisabled(segmentEditor.editing ? TutorialDisabledReasons.IS_EDIT_MODE : TutorialDisabledReasons.NONE)
            skip();
            setShowTutorial(false)
            
        } else {
            setTutorialDisabled(TutorialDisabledReasons.NONE)
            setShowTutorial(true);
        }


        if(tutorial.step!==0 && tutorialDisabled===TutorialDisabledReasons.NONE){
            if(segments.hideSegments || segments.undefinedMode) setPrevSketchState({showUndefined: segments.undefinedMode, hideMasks: segments.hideSegments})
            if (segments.hideSegments) segments.hideSegments = false
            if (segments.undefinedMode) segments.undefinedMode = false;
        } 
        if(tutorial.step===0) setShowTutorial(false)


    }, [list, segments, tutorial.step, segmentEditor?.editing, list.length, tutorialDisabled])

    return (
        <div className="sketch">
            <MobileUpload />
            <Header type="sketch" />
            <div className="sketch__body">
                <LeftBar listener={listener} setListener={setListener}/>
                <Drawing listener={listener} setListener={setListener} />
                <List />
            </div>
            {(showTutorial) && <Tutorial />}
        </div >
    );
}
