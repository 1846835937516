export enum ModalType {
    NONE = 'NONE',
    CREATE_GROUP = 'CREATE_GROUP',
    JOIN = 'JOIN',
    SKETCH_LEAVING = 'SKETCH_LEAVING',
    EDIT_NATIVE_MASK = 'EDIT_NATIVE_MASK',
    ERROR = 'ERROR',
    ATTACH_ERROR = 'ATTACH_ERROR',
    SHARE = 'SHARE',
}

export type CreateGroupModal = {
    type: ModalType.CREATE_GROUP;
    props: {
        res: (value: { including: boolean; saving: boolean; }) => void;
        rej: () => void;
    };
};

export type JoinModal = {
    type: ModalType.JOIN;
    props: {
        res: (value: boolean) => void;
        rej: () => void;
    };
};

export type EditNativeMaskModal = {
    type: ModalType.EDIT_NATIVE_MASK;
    props: {
        res: (value: null) => void;
        rej: () => void;
    };
}

export type ShareRenderModal = {
    type: ModalType.SHARE;
    props: {
        id: string;
    };
};

export type Modal = {
    type: ModalType.NONE;
    props?: undefined;
} | {
    type: ModalType.ERROR;
    props?: undefined;
} | {
    type: ModalType.SKETCH_LEAVING;
    props?: undefined;
} | {
    type: ModalType.ATTACH_ERROR;
    props?: undefined;
} | ShareRenderModal | CreateGroupModal | EditNativeMaskModal | JoinModal;
