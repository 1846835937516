import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import Button from 'Components/Button';
import * as Listeners from '../Listeners';
import { Group, Segments, useEditor, useSegments } from 'entities/sketch/Segment';
import { ReactComponent as ButtonSVG0 } from './icons/button0.svg';
import { ReactComponent as ButtonSVG1 } from './icons/button1.svg';
import { ReactComponent as ButtonSVG2 } from './icons/button2.svg';
import { ReactComponent as ButtonSVG3 } from './icons/button3.svg';
import { ReactComponent as ButtonSVG4 } from './icons/button4.svg';
import { ReactComponent as EraserSVG } from './icons/eraser.svg';
import { ReactComponent as BrushSVG } from './icons/brush.svg';
import { ReactComponent as UndoSvg } from './icons/arrow_left.svg';
import { ReactComponent as RendoSvg } from './icons/arrow_right.svg';
import { ReactComponent as HightlightSvg } from './icons/highlight1.svg';
import { ReactComponent as CursorHandSvg } from './icons/hand.svg';
import { EditNativeMaskModal, ModalType, openModal } from 'entities/modal';
import { setHint } from 'entities/hint';
import { useHotKey } from 'hooks';
import { $prevSketchState, $tutorial, setPrevSketchState } from 'entities/tutorial';
import './LeftBar.scss';


type LeftBarProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
};

class EndingButtons {
    constructor(protected readonly segments: Segments) { }
    handleDone() { };
    handleCancel() { };
}

class EditEndingButton extends EndingButtons {
    handleDone(): void {
        this.segments.endEditing();
    }

    handleCancel() {
        this.segments.segmentEditor.cancel();
    }
}

class CreateEndingButton extends EndingButtons {
    handleDone(): void {
        this.segments.createSegment();
    }

    handleCancel() {
        this.segments.segmentEditor.editing = false;
    }
}

export default function LeftBar({ listener, setListener }: LeftBarProps) {
    const editingGroup = useSegments('editingGroup');
    const segmentEditor = useSegments('segmentEditor');
    const editing = useEditor('editing');
    const undoArray = useEditor('undo');
    const rendoArray = useEditor('rendo');
    const segments = useSegments('self');
    const editingSegment = useEditor('segment');
    const tutorial = useStore($tutorial)
    const [endingButtons, setEndingButtons] = useState<EndingButtons>(() => new EndingButtons(segments));

    const selectDisabled = useMemo(() => editing, [editing]);

    const undoDisabled = useMemo(() => Number(undoArray?.length) < 2, [undoArray]);
    const rendoDisabled = useMemo(() => !rendoArray?.length, [rendoArray]);

    const undo = useCallback(() => {
        if (undoDisabled) return;
        segmentEditor.undoAction();
    }, [segmentEditor, undoDisabled]);

    const rendo = useCallback(() => {
        if (rendoDisabled) return;
        segmentEditor.rendoAction();
    }, [segmentEditor, rendoDisabled]);

    useHotKey('z', undo, useMemo(() => ({ modifier: 'ctrlKey' }), []));
    useHotKey('y', rendo, useMemo(() => ({ modifier: 'ctrlKey' }), []));

    useEffect(() => {
        if (editing) return setListener(new Listeners.BoxListener(segments));
        if (editingGroup) return setListener(new Listeners.EditGroup(segments));
        setListener(new Listeners.Select(segments));
    }, [editing, setListener, editingGroup, segments]);

    useEffect(() => {
        if (!editing) return;
        if (editingSegment.name === -1) setEndingButtons(new CreateEndingButton(segments));
        else setEndingButtons(new EditEndingButton(segments));
    }, [editing, editingSegment, segments]);

    const handleClickHightlight = useCallback(() => {
        if (selectDisabled) return;

        if (editingGroup) return setListener(new Listeners.SelectForGroup(segments));
        else return setListener(new Listeners.Select(segments));
    }, [selectDisabled, segments, setListener, editingGroup]);

    const clickEsc = useCallback(() => {
        if (!editing) return;
        endingButtons.handleCancel();
    }, [editing, endingButtons]);

    useHotKey('Escape', clickEsc);

    return (
        <div className={classNames({ "left-bar": true, })}>
            <div className="left-bar__arrows"
                id='undo_redo'
            >
                <div className={classNames({ 'left-bar__undo': true, 'left-bar__undo_disabled': undoDisabled })}
                    onClick={undo}
                    onMouseEnter={() => setHint({id:'undo_redo'})}
                    onMouseLeave={() => setHint(null)}>
                    <UndoSvg />
                </div>
                <div className={classNames({ 'left-bar__rendo': true, 'left-bar__rendo_disabled': rendoDisabled })}
                    onClick={rendo}
                    onMouseEnter={() => setHint({id:'undo_redo'})}
                    onMouseLeave={() => setHint(null)}>
                    <RendoSvg />
                </div>
            </div>
            <div className='left-bar__cursor-buttons_wrapper'
                id='left-bar__cursor-buttons'>
                <div className="left-bar__cursor-buttons">
                    <div
                        className={classNames({ 'left-bar__cursor-highlight': true, 'left-bar__cursor-highlight_active': listener instanceof Listeners.Select, "left-bar_bttns_disabled": editing })}
                        onClick={handleClickHightlight}
                        id="select"
                        onMouseEnter={() => setHint({id:'select'})}
                        onMouseLeave={() => setHint(null)}
                    >
                        <HightlightSvg className='select_cursor' />
                    </div>
                    <div
                        className={classNames({ 'left-bar__cursor-hand': true, 'left-bar__cursor-hand_active': listener instanceof Listeners.Zoom })}
                        onClick={() => setListener(new Listeners.Zoom(segments))}
                        id="move"
                        onMouseEnter={() => setHint({id:'move'})}
                        onMouseLeave={() => setHint(null)}
                    >
                        <CursorHandSvg className='move_cursor' />
                    </div>
                </div>
            </div>
            {editing || tutorial.step === 4 ? <EditButtons listener={listener} setListener={setListener} endingButtons={endingButtons} /> : <IndicateButton />}
        </div >
    );
}

type EditButtonsProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
    endingButtons: EndingButtons;
};

function EditButtons({ listener, setListener, endingButtons }: EditButtonsProps) {
    const undoArray = useEditor('undo');
    const segments = useSegments('self');
    const prevSketchState = useStore($prevSketchState)

    return (
        <div className="left-bar__buttons_wrapper" id="left-bar__buttons_wrapper">
            <div className="left-bar__buttons_options">
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.BoxListener })}
                    onClick={() => setListener(new Listeners.BoxListener(segments))}
                    id="left-bar__icon1"
                    onMouseEnter={() => setHint({id:'left-bar__icon1'})}
                    onMouseLeave={() => setHint(null)}
                >
                    <div className="left-bar__icon1">
                        <ButtonSVG1 className='left-bar__icon' />
                    </div>
                    <div className="left-bar__button-text">FRAME<br />TO ADD</div>
                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.PositivePoint })}
                    onClick={() => setListener(new Listeners.PositivePoint(segments))}
                    id="left-bar__icon2"
                    onMouseEnter={() => setHint({id:'left-bar__icon2'})}
                    onMouseLeave={() => setHint(null)}>
                    <div className="left-bar__icon2">
                        <ButtonSVG2 className='left-bar__icon' />
                    </div>
                    <div className="left-bar__button-text">POINT<br />TO ADD</div>

                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.NegativePoint })}
                    onClick={() => setListener(new Listeners.NegativePoint(segments))}
                    id="left-bar__icon3"
                    onMouseEnter={() => setHint({id:'left-bar__icon3'})}
                    onMouseLeave={() => setHint(null)}
                >
                    <div className="left-bar__icon3">
                        <ButtonSVG3 />
                    </div>
                    <div className="left-bar__button-text">POINT<br />TO exclude</div>
                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.PointRemover })}
                    onClick={() => setListener(new Listeners.PointRemover(segments))}
                    id="left-bar__icon4"
                    onMouseEnter={() => setHint({id:'left-bar__icon4'})}
                    onMouseLeave={() => setHint(null)}
                >
                    <ButtonSVG4 className='left-bar__icon' />
                    <div className="left-bar__button-text">REMOVE POINT</div>
                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.BrushListener })}
                    onClick={() => setListener(new Listeners.BrushListener(segments))}
                    id="left-bar__icon5"
                    onMouseEnter={() => setHint({id:'left-bar__icon5'})}
                    onMouseLeave={() => setHint(null)}
                >
                    <BrushSVG className='left-bar__icon' />
                    <div className="left-bar__button-text">BRUSH</div>
                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.EraserListener })}
                    onClick={() => setListener(new Listeners.EraserListener(segments))}
                    id="left-bar__icon6"
                    onMouseEnter={() => setHint({id: 'left-bar__icon6'})}
                    onMouseLeave={() => setHint(null)}
                >
                    <div className="left-bar__icon6">
                        <EraserSVG className='left-bar__icon' />
                    </div>
                    <div className="left-bar__button-text">ERASER</div>
                </div>
            </div>
            <Button disabled={Number(undoArray?.length) < 2} icon="success" size="secondary" color='dark' className="left-bar__done-button" onClick={() => {
                endingButtons.handleDone()
                segments.undefinedMode = prevSketchState.showUndefined
                segments.hideSegments = prevSketchState.hideMasks

                setPrevSketchState({ showUndefined: false, hideMasks: false })
            }
            }>DONE</Button>
            <Button size="secondary" className="left-bar__cancel-button" onClick={() => {
                segments.undefinedMode = prevSketchState.showUndefined
                segments.hideSegments = prevSketchState.hideMasks

                setPrevSketchState({ showUndefined: false, hideMasks: false })
                endingButtons.handleCancel()
            }}>CANCEL</Button>
        </div>
    );
}

function IndicateButton() {
    const segments = useSegments('self');
    const editingGroup = useSegments('editingGroup');
    const selectedElements = useSegments('selectedElements');
    const tutorial = useStore($tutorial);
    
    const disabled = useMemo(() => {
        if (tutorial.step !== 0 && editingGroup) return false
        if (editingGroup) return true;
        if (selectedElements.length > 1) return true;
        if (selectedElements[0] instanceof Group) return true;
    }, [editingGroup, selectedElements, tutorial.step]);

    const onClick = useCallback(async () => {
        if (disabled) return;
        const segment = selectedElements[0];
        if (segment instanceof Group) throw new Error('Selected element instanceof Group.');

        if (segments.hideSegments || segments.undefinedMode) {
            setPrevSketchState({ showUndefined: segments.undefinedMode, hideMasks: segments.hideSegments })
            if (segments.hideSegments) segments.hideSegments = false
            if (segments.undefinedMode) segments.undefinedMode = false
        }

        if (!segment) {
            const segment = segments.segmentEditor.createNewSegment();
            segments.editSegment(segment);
        } else {
            try {
                if (segment.native) {
                    const skip = localStorage.getItem('edit_native');
                    if (skip === null || !JSON.parse(skip)) await new Promise<Parameters<EditNativeMaskModal['props']['res']>[0]>((res, rej) => openModal({ type: ModalType.EDIT_NATIVE_MASK, props: { res, rej } }));
                }
                segments.editSegment(segment);
            } catch (e) { }
        }
    }, [segments, selectedElements, disabled]);

    return (
        <div
            id="left-bar__button_chair"
            className={classNames({
                'left-bar__button': true,
                'left-bar__button_disabled': disabled,
                'left-bar__button_disabled-tutorual': tutorial.step !== 0 && (selectedElements[0] instanceof Group)
            })}
            onClick={onClick}
            onMouseEnter={() => setHint({id:'left-bar__button_chair'})}
            onMouseLeave={() => setHint(null)}
        >
            <div className="left-bar__icon0">
                <ButtonSVG0 className='chair_icon' />
            </div>
            <div className="left-bar__button-text">{selectedElements.length ? 'Edit mask' : 'Indicate to describe'}</div>
        </div >
    );
}
