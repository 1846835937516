import config from 'config';
import { AttachmentProps, AttachmentType, Group, Segment, useSegments } from 'entities/sketch/Segment';
import { useStore } from 'effector-react';
import { $models, setElementForAttach } from 'entities/library';
import { Model } from 'services/library';
import './Products.scss';
import { Tab } from '../Attachments';
import Button from 'Components/Button';
import { Notifications, setNotification } from 'entities/notification';
import { setHelpSection } from 'entities/help';
import { HelpSections } from 'Components/Help/helpers/helpers';


type Props = {
    element: Segment | Group;
    setTab: (tab: Tab) => void;
}

export default function Products({ element, setTab }: Props) {

    const segments = useSegments('self');
    const models = useStore($models);
    function selectModel(model: Model) {
        const attachment: AttachmentProps = {
            filename: model.modelName,
            originalname: model.previewImageName,
            note: '',
            type: AttachmentType.MODEL,
        };
        if (element.attachments.length > 0) {
            element.unattachAll();
            setNotification(Notifications.PREEXISTING_ATTACHMENTS_INFO)
        }

        element.attach(attachment);
        segments.selectOneElement(element);
        setElementForAttach(null);
        setTab(Tab.UPLOAD)
    }
    return (
        <>

            <div className='products-attach-wrapper'>
                <div className='products-attach_descr'>
                    <p>Try applying products that the artificial intelligence of io-buro already knows.</p>
                    <div className='read_more' onClick={()=>setHelpSection({section: HelpSections.DESCRIBE, itemId: 'help-section__product'})}>
                        <p>read more</p>
                        <div className='read_more-icon' />
                    </div>

                </div>
                <div className="products-attach">
                    {
                        models.map(model => (
                            <div key={`product_${element.id}-${model.createdAt}`}

                                className="products-attach__container">
                                {/* <div className={classNames({ 'products-attach__checkbox': true, 'attach-checkbox_active': selectedModel === model })} />
                                        <div className={classNames({ 'products-attach__border': true, 'products-attach__border_selected': selectedModel === model })}>
                                        </div> */}

                                <div className='products-attach__model-image-wrapper'>
                                    <img
                                        src={config.trainerUrl + '/preview-images/' + encodeURIComponent(model.previewImageName)}
                                        alt={model.previewImageName}
                                        className="products-attach__model-image"
                                    />

                                </div>
                                <div className='products-attach__model-info-wrapper'>
                                    <div className='products-attach__model-description'>Chloe Marble Pinth Block Large Coffee Table</div>
                                    <div className='products-attach__model-row'>
                                        <div className='products-attach__model-designed'>
                                            <div className='model-designed_by'>Designed by</div>
                                            <p className='products-attach__model-title'>Audo Copenhage</p>
                                        </div>
                                        <Button color='dark' onClick={() => { selectModel(model) }}>Add</Button>
                                    </div>
                                </div>
                                {/* <div className="products-attach__model-title">{model.modelName}</div> */}
                            </div>
                        ))
                    }
                </div>
            </div>
        </>


    )
}
