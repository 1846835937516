import { useEffect } from "react";
import { useStore } from "effector-react";
import { $tutorial } from 'entities/tutorial';
import { useSegments } from "entities/sketch/Segment";

const validClasses = ['segment', 'group', 'segment segment_active', 'group group_active'];

export default function useStyles() {
    const tutorial = useStore($tutorial);
    const undefinedMode = useSegments('undefinedMode')

    const setStyles = (elements: NodeListOf<HTMLElement>, index: number, idName: string) => {
        elements.forEach((element, i) => {
            if (i === index) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                element.style.position = 'relative';
                // element.id = `${idName}-${index}`;
                element.style.zIndex = '7';
                element.style.backgroundColor= 'white';
                element.style.pointerEvents='none';
            
            }
        });
    };

    const resetStyles = (elements: NodeListOf<HTMLElement>, index: number) => {
        elements.forEach((element, i) => {
            if ((i === index)) {
                element.style.zIndex = '0';
                element.style.backgroundColor= ''
                element.style.pointerEvents='auto'
            }
        })
    }

    useEffect(() => {
        if(undefinedMode) return ;
        const step = tutorial.step;
        const listWrapper = document.getElementById('list__table-elements')
        if (!listWrapper) return 

        const listWrapperChild = listWrapper.children[0] as HTMLElement;
        // const elements = Array.from(listWrapperChild.children) 
        const elements = Array.from(listWrapperChild.children).map((element)=>{return element.children[0]})
        const filteredArray = elements.filter((element) => validClasses.includes(element.className)) as unknown as NodeListOf<HTMLElement>;

        resetStyles(filteredArray, 0);
        resetStyles(filteredArray, 1);
        resetStyles(filteredArray, 2);

        if (step === 1) {
            setStyles(filteredArray, 0, 'segment');
        } 
        if (step === 2) {
            setStyles(filteredArray, 1, 'segment');
        }
        if (step === 3) {
            setStyles(filteredArray, 2, 'segment');
        }
        if(step === 0 ){
            resetStyles(filteredArray, 0);
            resetStyles(filteredArray, 1);
            resetStyles(filteredArray, 2);
    
        }
    }, [tutorial.step, undefinedMode]);
}