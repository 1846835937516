import { useEffect, useState } from 'react';
import { useSegments } from 'entities/sketch/Segment';
import { RoutePath, goTo } from 'entities/router';
import { useStore } from 'effector-react';
import { $queue, $segments } from 'entities/sketch';
import { Line } from 'rc-progress';
import './Process.scss';


export default function Process() {
    const [percent, setPercent] = useState<number>(0);
    const segments = useStore($segments);
    const queue = useStore($queue);

    useEffect(() => {
        const interval = setInterval(() => {
            if (percent < 100) {
                setPercent(prevPercent => prevPercent + 10);
            }
        }, 9000);

        return () => clearInterval(interval);
    }, [percent]);

    function getOrdinal(n: number) {
        if (n === 0) return ''
        if (n % 10 === 1 && n % 100 !== 11) return 'st';
        if (n % 10 === 2 && n % 100 !== 12) return 'nd';
        if (n % 10 === 3 && n % 100 !== 13) return 'rd';
        return 'th';
    }


    return (
        <div className='upload-process-modal__container'>
            {segments && <UseLoading setPercent={setPercent} />}
            <div className='upload-process-modal'>
                <div className="upload-process-modal__about">Stay tuned... </div>
                <div className="upload-process-modal__about">AI is recognizing the objects in your sketch. This process may take a few minutes.</div>
                <div className="progress-bar__queue" style={{ visibility: queue !== 0 ? 'visible' : 'hidden' }}>You are {queue === -1 ? '...' : `${queue}${getOrdinal(queue)}`} in line.</div>
                { queue !== 0 ?
                    <div className='upload-process-modal__spinner_wrapper'>
                        <div className='upload-process-modal__spinner' />
                    </div> :
                    <div className='progress-bar_wrapper'>
                        <Line percent={percent}
                            strokeWidth={4}
                            strokeColor="#000000"
                            trailWidth={4}
                            trailColor='#ffffff'
                            strokeLinecap="square"
                            className='progress-bar' />
                        <p>{percent}<span className='percent-icon'>&#37;</span></p>
                    </div>
                }
            </div>
        </div>
    );
}

function UseLoading({ setPercent }: { setPercent: (value: number) => void }) {
    const loaded = useSegments('loaded');

    useEffect(() => {
        if (loaded) {
            setPercent(100);
            setTimeout(() => {
                goTo(RoutePath.SKETCH);
            }, 1000);
        }
    }, [loaded, setPercent]);

    return null;
}